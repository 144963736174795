export const getLabelForCommentByPostId = (postId) => {
   if (typeof postId !== 'string') {
      return 'Комментарий к посту'
   }
   switch (true) {
      case postId.includes('video'):
         return 'Комментарий к видео'
      default:
         return 'Комментарий к посту'
   }
}

export const convertDate = (timestamp, showTime = false) => {
   let date = new Date(0)
   let currentDate = new Date()
   date.setUTCSeconds(timestamp)

   return currentDate.getDate() === date.getDate() &&
   currentDate.getMonth() === date.getMonth() &&
   currentDate.getFullYear() === date.getFullYear()
       ? `${date.getHours()}:${
           date.getMinutes() < 10 ? '0' : ''
       }${date.getMinutes()}`
       : `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${
           showTime ? `${date.getHours()}:${date.getMinutes() < 10 ? '0' : ''}${date.getMinutes()}` : ''}`
}

export const REVOKE_MESSAGE_TEXT = '🚫 Вы удалили данное сообщение.'
export const REVOKE_STATUS = 4