import './mailing.css'
import { useEffect, useState } from 'react'
import AddMailing from '../modals/AddMailing'
import MailingHistoryItem from './MailingHistoryItem'
import axios from 'axios'

const Mailing = ({
   tag,
   allowedTag,
   status,
   allowedStatus,
   channelList,
   allowedChannel,
   currentUser,
}) => {
   const [historyMailing, setHistoryMailing] = useState([])
   const [nextHistoryMailingCursor, setNextHistoryMailingCursor] =
      useState(null)

   useEffect(() => {
      axios({
         method: 'get',
         url: `/api/v1/mass-send/get?limit=20`,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               console.log(response.data.data.error)
            } else {
               setNextHistoryMailingCursor(response.data.data.next_cursor)
               setHistoryMailing(response.data.data.massMessages)
            }
         })
         .catch((err) => {
            console.log(err)
         })
   }, [])



   const loadHistoryMailing = () => {
      nextHistoryMailingCursor &&
         axios({
            method: 'get',
            url: `/api/v1/mass-send/get?limit=20&cursor=${nextHistoryMailingCursor}`,
            withCredentials: true,
         })
            .then((response) => {
               if (response.data.error) {
                  console.log(response.data.data.error)
               } else {
                  setNextHistoryMailingCursor(response.data.data.next_cursor)
                  setHistoryMailing([...historyMailing, ...response.data.data.massMessages])
               }
            })
            .catch((err) => {
               console.log(err)
            })
   }


   const handleHistoryMailingScroll = (e) => {
      if (e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight) {
         nextHistoryMailingCursor && loadHistoryMailing()
      }
   }

   const [isShowAddMailingModal, setIsShowAddMailingModal] = useState(false)
   const toggleShowAddMailingModal = () => {
      setIsShowAddMailingModal(!isShowAddMailingModal)
   }
   const [currentPage, setCurrentPage] = useState(1)
   const toggleCurrentPage = (page) => {
      return setCurrentPage(page)
   }
   return (
      <div className="mailing-wrapper" onScroll={handleHistoryMailingScroll}>
         <div className="mailing-header">
            <div className="mailing-header_navigation">
               <button
                  className={
                     currentPage === 1
                        ? 'mailing-header_navigation-button-active'
                        : 'mailing-header_navigation-button'
                  }
                  onClick={() => toggleCurrentPage(1)}
               >
                  Рассылки
               </button>
               <button
                  className={
                     currentPage === 2
                        ? 'mailing-header_navigation-button-active'
                        : 'mailing-header_navigation-button'
                  }
                  onClick={() => toggleCurrentPage(2)}
               >
                  История рассылок
               </button>
            </div>
         </div>
         {currentPage === 1 ? (
            <>
               <AddMailing
                  isVisible={isShowAddMailingModal}
                  toggleShowAddMailingModal={toggleShowAddMailingModal}
                  tag={tag}
                  allowedTag={allowedTag}
                  status={status}
                  allowedStatus={allowedStatus}
                  channelList={channelList.filter(
                     (channel) => channel.status === 'active'
                  )}
                  allowedChannel={allowedChannel}
                  setHistoryMailing={setHistoryMailing}
                  currentUser={currentUser}
               />
               <button
                  className="mailing-add-button"
                  onClick={toggleShowAddMailingModal}
               >
                  <svg
                     width="16"
                     height="16"
                     viewBox="0 0 22 22"
                     xmlns="http://www.w3.org/2000/svg"
                  >
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.029 3.89575C11.4086 3.89625 11.716 4.20446 11.7156 4.58415L11.6987 17.4175C11.6982 17.7972 11.39 18.1046 11.0103 18.1041C10.6306 18.1036 10.3232 17.7954 10.3237 17.4157L10.3406 4.58235C10.341 4.20266 10.6493 3.89525 11.029 3.89575Z"
                     />
                     <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.896 11C3.896 10.6203 4.2038 10.3125 4.5835 10.3125H17.4168C17.7965 10.3125 18.1043 10.6203 18.1043 11C18.1043 11.3797 17.7965 11.6875 17.4168 11.6875H4.5835C4.2038 11.6875 3.896 11.3797 3.896 11Z"
                     />
                  </svg>
                  <span>Создать рассылку</span>
               </button>
            </>
         ) : historyMailing.length !== 0 ? (
            <ul className="mailing-history-list">
               <li className="mailing-history-header">
                  <div className="mailing-history-header_block">
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">
                           Имя менеджера
                        </span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Каналы</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Статусы</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Теги</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Текст</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">Вложения</span>
                     </div>
                     <div className="mailing-history-block">
                        <span className="mailing-history-title">
                           Дата и время
                        </span>
                     </div>
                  </div>
               </li>
               {historyMailing.map((item) => {
                  return (
                     <MailingHistoryItem
                        key={item.id}
                        item={item}
                        tag={tag}
                        status={status}
                        channelList={channelList}
                     />
                  )
               })}
            </ul>
         ) : (
            <span className="tags-status-settings_subtitle">
               Вы пока не отправили ни одной рассылки
            </span>
         )}
      </div>
   )
}

export default Mailing
