import './managerMainbar.css'
import PaginationItem from '../settings/PaginationItem'
import React, { useState } from 'react'
import ManagerPermissionItem from './ManagerPermissionItem'
const ManagerPermissionList = ({
   title,
   elements,
   permissions,
   addPermission,
   deletePermission,
   isAllAllow,
   toggleAllPermissions,
}) => {
   const [currentPage, setCurrentPage] = useState(1)
   const [elementsPerPage, setElementsPerPage] = useState(7)
   const lastElementIndex = currentPage * elementsPerPage
   const firstElementIndex = lastElementIndex - elementsPerPage
   const currentElements = elements.slice(firstElementIndex, lastElementIndex)

   const pageNumbers = []

   for (let i = 1; i <= Math.ceil(elements.length / elementsPerPage); i++) {
      pageNumbers.push(i)
   }

   const [activePermissions, setActivePermissions] = useState([])
   const activeToggleButton = {
      backgroundColor: 'white',
      marginLeft: 'auto',
   }

   const inActiveToggleButton = {
      backgroundColor: 'white',
      marginRight: 'auto',
   }

   return (
      <div className={'manager-permission-control_list'}>
         <span className="manager-permission-control-field-name">{title}</span>
         <ul className="manager-permission_list">
            <div>
               <div className="manager-permission-control-field">
                  <span className="manager-permission-field-name">Все</span>
                  <div
                     className={
                        isAllAllow
                           ? 'manager-permission-controller-active'
                           : 'manager-permission-controller'
                     }
                     onClick={toggleAllPermissions}
                  >
                     <div
                        className="manager-permission-toggle-button"
                        style={
                           isAllAllow
                              ? activeToggleButton
                              : inActiveToggleButton
                        }
                     ></div>
                  </div>
               </div>
            </div>
            {currentElements.map((element) => {
               return (
                  <ManagerPermissionItem
                     isAllAllow={isAllAllow}
                     toggleAllPermissions={toggleAllPermissions}
                     activeToggleButton={activeToggleButton}
                     inActiveToggleButton={inActiveToggleButton}
                     permissions={permissions}
                     activePermissions={activePermissions}
                     setActivePermissions={setActivePermissions}
                     key={element.id}
                     element={element}
                     addPermission={addPermission}
                     deletePermission={deletePermission}
                  />
               )
            })}
         </ul>
         {elements.length > elementsPerPage && (
            <div className="manager-permission-pagination">
               <PaginationItem
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageNumbers={pageNumbers}
               />
            </div>
         )}
      </div>
   )
}
export default ManagerPermissionList
