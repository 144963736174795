import { CSSTransition } from 'react-transition-group'
import './addContact.css'
import WarningBlock from './WarningBlock'
import React, { useEffect, useState } from 'react'

const AddTemplate = ({
   isVisible,
   title,
   text,
   element,
   accept,
   cancel,
   setTemplates,
}) => {
   const [nameTemplateField, setNameTemplateField] = useState('')
   const [nameTemplateFieldFilled, setNameTemplateFieldFilled] = useState(false)
   const [nameTemplateFieldEnough, setNameTemplateFieldEnough] = useState(false)

   const [textTemplateField, setTextTemplateField] = useState('')
   const [textTemplateFieldFilled, setTextTemplateFieldFilled] = useState(false)

   const [fileTemplateField, setFileTemplateField] = useState([])

   const clearInputs = () => {
      setNameTemplateField(element ? element.name : '')
      setNameTemplateFieldFilled(false)
      setNameTemplateFieldEnough(false)
      setTextTemplateField(element ? element.text : '')
      setTextTemplateFieldFilled(false)
      setFileTemplateField([])
   }

   useEffect(() => {
      setNameTemplateField(element ? element.name : '')
      setTextTemplateField(element ? element.text : '')
   }, [element])

   const TemplateHandler = () => {
      if (nameTemplateField.length === 0) {
         setNameTemplateFieldFilled(true)
      } else {
         setNameTemplateFieldFilled(false)
      }
      if (nameTemplateField.length <= 2 && nameTemplateField.length > 0) {
         setNameTemplateFieldEnough(true)
      } else {
         setNameTemplateFieldEnough(false)
      }
      if (textTemplateField.length === 0) {
         setTextTemplateFieldFilled(true)
      } else {
         setTextTemplateFieldFilled(false)
      }
      if (
         nameTemplateField.length > 0 &&
         nameTemplateField.length > 2 &&
         textTemplateField.length > 0
      ) {
         var bodyFormData = new FormData()
         bodyFormData.append('name', nameTemplateField)
         bodyFormData.append('text', textTemplateField)
         if (fileTemplateField.length !== 0) {
            bodyFormData.append('file', fileTemplateField[0])
         }
         accept(bodyFormData)
         clearInputs()
         cancel()
      }
   }

   return (
      <CSSTransition
         in={isVisible}
         classNames="warning"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="warning">
            <div className="add-contact-wrapper">
               <div className="add-contact-header">
                  <div className="add-contact-header-text">{title}</div>
                  <div className="add-contact-close">
                     <button
                        className="add-contact-close-button"
                        onClick={() => {
                           cancel()
                           clearInputs()
                        }}
                     >
                        <img src="/assets/controls/close.svg" alt="" />
                     </button>
                  </div>
               </div>

               <div className="add-contact-form-control">
                  <label
                     htmlFor=""
                     className={
                        nameTemplateFieldFilled || nameTemplateFieldEnough
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     Название шаблона*
                  </label>
                  <input
                     type="text"
                     className={
                        nameTemplateFieldFilled || nameTemplateFieldEnough
                           ? 'add-contact-form-control-input-red'
                           : 'add-contact-form-control-input'
                     }
                     placeholder="Введите название шаблона"
                     value={nameTemplateField}
                     maxLength="46"
                     minLength="3"
                     onChange={(e) => setNameTemplateField(e.target.value)}
                  />
               </div>
               <div className="add-contact-form-control">
                  <label
                     htmlFor=""
                     className={
                        textTemplateFieldFilled
                           ? 'add-contact-form-control-label-red'
                           : 'add-contact-form-control-label'
                     }
                  >
                     Текст шаблона*
                  </label>
                  <textarea
                     className={
                        textTemplateFieldFilled
                           ? 'add-contact-textarea-red'
                           : 'add-contact-textarea'
                     }
                     value={textTemplateField}
                     onChange={(e) => setTextTemplateField(e.target.value)}
                     placeholder="Текст шаблона"
                     minLength="1"
                     maxLength="1000"
                  ></textarea>
               </div>
               <div
                  className="add-contact-form-control"
                  style={{ display: 'flex', flexDirection: 'row', gap: '8px' }}
               >
                  <label
                     className={
                        fileTemplateField.length === 0
                           ? 'settings-template_file-button'
                           : 'settings-template_file-button-limit'
                     }
                     htmlFor="add-file"
                  >
                     <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                        fill={
                           fileTemplateField.length === 0
                              ? '#40b7d9'
                              : '#B8C5C9'
                        }
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M15.3587 4.95927C16.8232 3.49478 19.1976 3.4948 20.6621 4.95926L20.1328 5.4885L20.6621 4.95927C22.1265 6.42372 22.1265 8.79808 20.6621 10.2626L20.6621 10.2626L11.8232 19.1014C9.77296 21.1516 6.44884 21.1516 4.39859 19.1014L4.92892 18.571L4.39859 19.1014C2.34834 17.0511 2.34834 13.727 4.39859 11.6768L11.8232 4.25215C12.1161 3.95925 12.591 3.95925 12.8839 4.25215C13.1767 4.54504 13.1767 5.01991 12.8839 5.31281L5.45925 12.7374L4.92892 12.2071L5.45925 12.7374C3.99478 14.2019 3.99478 16.5763 5.45925 18.0407L4.93823 18.5617L5.45925 18.0407C6.92371 19.5052 9.29809 19.5052 10.7625 18.0407L11.2575 18.5357L10.7625 18.0407L19.6014 9.20193C19.6014 9.20192 19.6014 9.20192 19.6014 9.20192C20.4801 8.3232 20.48 6.89857 19.6014 6.01993C18.7227 5.14123 17.2981 5.14124 16.4194 6.01992L16.4194 6.01993L7.58058 14.8587C7.28769 15.1516 7.28769 15.6265 7.58058 15.9194C7.87349 16.2123 8.34835 16.2123 8.64124 15.9194L16.0658 8.49477C16.3587 8.20187 16.8336 8.20187 17.1265 8.49477C17.4194 8.78766 17.4194 9.26253 17.1265 9.55543L9.70191 16.9801C8.82321 17.8588 7.39859 17.8588 6.5199 16.9801L6.51987 16.9801C5.64126 16.1014 5.64126 14.6768 6.51987 13.7981L6.5199 13.7981L15.3587 4.95928C15.3587 4.95927 15.3587 4.95927 15.3587 4.95927Z"
                        />
                     </svg>
                     {fileTemplateField.length === 0
                        ? 'Прикрепить файл'
                        : fileTemplateField[0].name}
                  </label>
                  <input
                     id="add-file"
                     type="file"
                     src={fileTemplateField}
                     onChange={(e) => {
                        setFileTemplateField([...e.target.files])
                     }}
                     className="settings-template_file-input"
                     accept=".jpg, .png, .gif, .jpeg, .webp, .bmp, .mp4, .m4a"
                  />
                  {fileTemplateField.length !== 0 && (
                     <img
                        width="16"
                        height="16"
                        style={{ margin: '0', cursor: 'pointer' }}
                        src="/assets/controls/close.svg"
                        onClick={() => {
                           setFileTemplateField([])
                        }}
                     ></img>
                  )}
               </div>

               {(nameTemplateFieldFilled || textTemplateFieldFilled) && (
                  <WarningBlock
                     title={'Все поля обязательны для заполнения.'}
                  />
               )}
               {nameTemplateFieldEnough && (
                  <WarningBlock title={'Введите более 3 символов.'} />
               )}
               <div className="add-contact-form-submit">
                  <button
                     className="add-contact-form-submit-button"
                     onClick={TemplateHandler}
                  >
                     {text}
                  </button>
               </div>
            </div>
         </div>
      </CSSTransition>
   )
}

export default AddTemplate
