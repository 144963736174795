import React from 'react'
import './ReplySection.css'
import { messageTypes } from '../../../utils/consts'

const ReplySection = ({ message, onReplySectionClose }) => {
   const { type, author, text, link, thumb, params } = message
   const image = thumb || params?.media_photo || (type === 'image' ? link : '')
   const title = author?.name || author?.username || ''
   const replyText = text || messageTypes[type]
   
   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/chat/clip.svg'
   }

   const handleClose = () => {
      onReplySectionClose()
   }

   return (
      <div className='reply-section'>
         <div className="reply-section__body">
            {image && (
               <div className='reply-section__image'>
                  <img 
                     src={image} 
                     alt="" 
                     onError={handleImageLoadingError} 
                  />
               </div>
            )}
            <div className="reply-section__info">
               <p className="reply-section__title">
                  <span>В ответ</span> {title}
               </p>
               <p className="reply-section__text">
                  {replyText}
               </p>
            </div>
         </div>
         <button
            className='reply-section__close-btn'
            type='button'
            onClick={handleClose}
         >
            <svg
               viewBox="0 0 24 24" 
               fill="none" 
               xmlns="http://www.w3.org/2000/svg"
            >
               <path d="M18.2997 5.70973C17.9097 5.31973 17.2797 5.31973 16.8897 5.70973L11.9997 10.5897L7.10973 5.69973C6.71973 5.30973 6.08973 5.30973 5.69973 5.69973C5.30973 6.08973 5.30973 6.71973 5.69973 7.10973L10.5897 11.9997L5.69973 16.8897C5.30973 17.2797 5.30973 17.9097 5.69973 18.2997C6.08973 18.6897 6.71973 18.6897 7.10973 18.2997L11.9997 13.4097L16.8897 18.2997C17.2797 18.6897 17.9097 18.6897 18.2997 18.2997C18.6897 17.9097 18.6897 17.2797 18.2997 16.8897L13.4097 11.9997L18.2997 7.10973C18.6797 6.72973 18.6797 6.08973 18.2997 5.70973Z" fill="#252525"/>
            </svg>
         </button>
      </div>
   )
}

export default ReplySection