const getIsMessageOfPrevMailer = (messages, currentMessage, index) => {
   const isCurrentMessageLast = index === messages.length - 1
   const prevMessage = messages[index + 1]

   if (isCurrentMessageLast) return false
   if (currentMessage.manager) {
      if (!prevMessage.manager) return false
      else if (currentMessage.manager.id !== prevMessage.manager.id) return false
   } else {
      if (prevMessage.manager) return false
      else if (currentMessage.author.id !== prevMessage.author.id) return false
   }

   return true
}

export default getIsMessageOfPrevMailer