import React, {useEffect, useState} from 'react'
import axios from 'axios'
import ReplyMessage from "./ReplyMessage";
import {isIOS, isMacOs} from "react-device-detect";
import OgvPlayer from "../OgvPlayer";
import {convertDate, REVOKE_MESSAGE_TEXT, REVOKE_STATUS} from "../utils/helper";
import {parseRef} from "../utils/ParseRef";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import ErrorDesc from "./ErrorDesc";
import MessageStatus from './MessageStatus'
import {checkCanRevokeMessage, getMailerName} from '../../utils'
import useAddMessageToUnreadOnVisibility from '../../hooks/useAddMessageToUnreadOnVisibility';
import { managerStatuses } from '../../utils/consts'

const Message = ({
   message,
   index,
   isMessageOfPrevMailer,
   setImagePreviewLink,
   setImagePreviewModal,
   setImagePreviewType,
   setImagePreviewId,
   setImagePreviewThumb,
   setCommentReplySection,
   autoReadStatus,
   fetchDialogData,
   setUnreadMessagesIds,
   handleMessageStatusClick,
   handleReplyMessageClick,
   selectedRepliedMessage,
   setSelectedRepliedMessage
}) => {
   const [messageText, setMessageText] = useState(message.text || '')
   const [messageStatus, setMessageStatus] = useState(message.status)
   const [isMessageActive, setIsMessageActive] = useState(false)

   const mailerName = getMailerName(message)
   const managerStatus = message.manager?.status
   const showManagerStatus = managerStatus === managerStatuses.inactive
   const canRevokeMessage = checkCanRevokeMessage(message)

   const handleMessageVisibility = (messageExternalId) => {
      setUnreadMessagesIds(ids => [...ids, messageExternalId])
   }

   const [messageRef] = useAddMessageToUnreadOnVisibility(autoReadStatus, message, handleMessageVisibility)

   useEffect(() => {
      setMessageStatus(message.status)
   },[message.status])

   useEffect(() => {
      setMessageText(message.text || '')
   },[message.text])

   const ogvFormatCheck = (link) => {
      let separatedString = link.split('.');
      let extension = separatedString[separatedString.length -1];
      let formats = ['oga', 'ogg', 'ogv'];

      return formats.includes(extension);
   }

   const handleReplyClick = (e, { message }) => {
      setCommentReplySection(message)
   }

   const getMessageText = (message) => {
      if (message.status === REVOKE_STATUS) {
         return parseRef(REVOKE_MESSAGE_TEXT)
      }
      if (message.type !== 'text') {
         if (message.link) {
            if (message.type === 'story') {
               return message.text &&
                   message.text !== message.link &&
                   messageText.split(':')[0]
            } else {
               return message.text &&
               message.text !== message.link
                   ? messageText
                   : ''
            }
         } else if (message.comment_id){
            return message.text &&
            message.text !== message.params.media_link
                ? messageText
                : ''
         }
      }
      return message.text &&
         message.text !== message.link
             ? parseRef(messageText)
             : ''
   }

   const onHandleRightClick = (e, data) => {
      axios
          .post(`/api/v1/message/revoke`, {
             messageId: data.message.id,
             dialogId: data.message.dialog_id,
          })
          .then((response) => {
             if (response.data.error === true) {
                alert('Произошла ошибка!')
                return
             }
             setMessageStatus(REVOKE_STATUS)
             setMessageText(REVOKE_MESSAGE_TEXT)
          })
          .catch((err) => {
             console.log(err)
             alert('Произошла ошибка!')
          })
   }

   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/chat/clip.svg'
   }

   const handleToggleIsMessageActive = () => setIsMessageActive(active => !active)

   const handleContextMenu = (e) => {
      e.preventDefault()
      e.stopPropagation()
      return false
   }

   useEffect(() => {
      if (selectedRepliedMessage === message.id && messageRef.current) {
         messageRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })
         handleToggleIsMessageActive()
         setTimeout(() => {
            handleToggleIsMessageActive()
            setSelectedRepliedMessage(null)
         }, 2500)
      }
   }, [selectedRepliedMessage])

   return (
       <div
           ref={messageRef}
           className={`${message.is_incoming ? 'mainbar-conversation-input-message-card' : 'mainbar-conversation-output-message-card'} ${isMessageActive ? 'mainbar-conversation-message-card_active' : ''}`}
           key={index}
           onContextMenu={handleContextMenu}
       >
          {!isMessageOfPrevMailer && (
            <p className='mainbar-conversation-message-mailer-name'>
               {mailerName}
               <span className={
                  `mainbar-conversation-message-mailer-name__status ${
                  showManagerStatus 
                     ? 'mainbar-conversation-message-mailer-name__status_show' 
                     : ''
               }`}>
                  {' (неактивный)'}
               </span>
            </p>
          )}
          <ContextMenuTrigger 
            id={String(message.id)} 
            attributes={{className: 'mainbar-conversation-message-card-wrapper'}} 
            holdToDisplay={500}
          >
             {message.reply_message && (
                <ReplyMessage
                   message={message.reply_message}
                   onClick={handleReplyMessageClick}
                />
             )}
             <p className="mainbar-conversation-message-card-text">
                {messageStatus === REVOKE_STATUS ? (
                    getMessageText(message)
                ) : (
                    <>
                       {message.type !== 'text' ? (
                           message.link ? (
                               message.type === 'image' ? (
                                   <>
                                      <img
                                          onClick={() => {
                                             setImagePreviewLink(message.link)
                                             setImagePreviewModal(true)
                                             setImagePreviewType(message.type)
                                             setImagePreviewId(message.id)
                                             setImagePreviewThumb(message.thumb)
                                          }}
                                          src={
                                             message.type === 'image'
                                                 ? message.link
                                                 : `/assets/chat/clip.svg`
                                          }
                                          alt=""
                                          style={
                                             message.type === 'image'
                                                 ? {
                                                    height: '150px',
                                                    width: '150px',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                 }
                                                 : {
                                                    maxHeight: '20px',
                                                    filter: 'brightness(1.5)',
                                                    marginRight: '5px',
                                                 }
                                          }
                                          onError={handleImageLoadingError}
                                      />
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                         {getMessageText(message)}
                                      </p>
                                   </>
                               ) : message.type === 'video' ? (
                                   <div
                                       style={{
                                          position: 'relative',
                                          width: 'auto',
                                       }}
                                       onClick={() => {
                                          setImagePreviewLink(message.link)
                                          setImagePreviewModal(true)
                                          setImagePreviewType(message.type)
                                          setImagePreviewId(message.id)
                                          setImagePreviewThumb(message.thumb)
                                       }}
                                   >
                                      <img
                                          src="/assets/chat/play.svg"
                                          alt=""
                                          style={{
                                             width: '50px',
                                             height: '50px',
                                             position: 'absolute',
                                             top: '50px',
                                             left: '50px',
                                             opacity: '0.7',
                                             cursor: 'pointer',
                                          }}
                                      />
                                      <img
                                          src={
                                             message.type === 'video'
                                                 ? message.thumb
                                                 : `/assets/chat/clip.svg`
                                          }
                                          alt=""
                                          style={
                                             message.type === 'video'
                                                 ? {
                                                    height: '150px',
                                                    width: '150px',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                 }
                                                 : {
                                                    maxHeight: '20px',
                                                    filter: 'brightness(1.5)',
                                                    marginRight: '5px',
                                                 }
                                          }
                                          onError={handleImageLoadingError}
                                      />
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                         {getMessageText(message)}
                                      </p>
                                   </div>
                               ) : message.type === 'media' ? (
                                   <>
                                      <a
                                          href={message.link}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                             color: 'inherit',
                                             display: 'block',
                                          }}
                                      >
                                         {message.link}
                                      </a>
                                      <img
                                          onClick={() => {
                                             setImagePreviewLink(message.thumb)
                                             setImagePreviewModal(true)
                                             setImagePreviewType(message.type)
                                             setImagePreviewId(message.id)
                                             setImagePreviewThumb(message.thumb)
                                          }}
                                          src={
                                             message.type === 'media'
                                                 ? message.thumb
                                                 : `/assets/chat/clip.svg`
                                          }
                                          alt=""
                                          style={
                                             message.type === 'media'
                                                 ? {
                                                    height: '150px',
                                                    width: '150px',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                 }
                                                 : {
                                                    maxHeight: '20px',
                                                    filter: 'brightness(1.5)',
                                                    marginRight: '5px',
                                                 }
                                          }
                                          onError={handleImageLoadingError}
                                      />
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                         {getMessageText(message)}
                                      </p>
                                   </>
                               ) : message.type === 'story' ? (
                                   <>
                                      <img
                                          onClick={() => {
                                             setImagePreviewLink(message.thumb)
                                             setImagePreviewModal(true)
                                             setImagePreviewType('media')
                                             setImagePreviewId(message.id)
                                             setImagePreviewThumb(message.thumb)
                                          }}
                                          src={
                                             message.type === 'story'
                                                 ? message.thumb
                                                 : `/assets/chat/clip.svg`
                                          }
                                          alt=""
                                          style={
                                             message.type === 'story'
                                                 ? {
                                                    height: '150px',
                                                    width: '150px',
                                                    objectFit: 'cover',
                                                    cursor: 'pointer',
                                                    marginTop: '10px'
                                                 }
                                                 : {
                                                    maxHeight: '20px',
                                                    filter: 'brightness(1.5)',
                                                    marginRight: '5px',
                                                 }
                                          }
                                          onError={handleImageLoadingError}
                                      />
                                       <p
                                           style={{
                                              color: 'inherit',
                                              fontSize: 'inherit',
                                              fontFamily: 'inherit',
                                           }}
                                       >
                                          Комментарий к истории:
                                       </p>
                                      <a
                                          href={message.link}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                             color: 'inherit',
                                             display: 'block',
                                          }}
                                      >
                                         {message.link}
                                      </a>
                                      <p
                                          style={{
                                             color: 'inherit',
                                             fontSize: 'inherit',
                                             fontFamily: 'inherit',
                                          }}
                                      >
                                          {getMessageText(message)}
                                      </p>
                                   </>
                               ) : message.type === 'audio' ? (
                                   <>
                                      {
                                         ((isMacOs || isIOS) && ogvFormatCheck(message.link)) ?
                                             <OgvPlayer
                                                 src={message.link}
                                             /> :
                                             <audio
                                                 controls
                                                 src={message.link}
                                                 className="audio_player"
                                             ></audio>
                                      }
                                   </>
                               ) : (
                                   message.type === 'document' && (
                                       <a
                                           href={message.link}
                                           download
                                           style={{
                                              color: 'inherit',
                                              display: 'block',
                                           }}
                                       >
                                          <img
                                              src="/assets/chat/clip.svg"
                                              style={{
                                                 maxHeight: '20px',
                                                 filter: 'brightness(1.5)',
                                                 marginRight: '5px',
                                              }}
                                              alt=""
                                          />
                                          {getMessageText(message)}
                                       </a>
                                   )
                               )
                           ) : (
                               <span>Файл загружается...</span>
                           )
                       ) : message.comment_id ? (
                           <>
                              <p
                                 style={{
                                    color: 'inherit',
                                    fontSize: 'inherit',
                                    fontFamily: 'inherit',
                                 }}
                              >
                                 Комментарий к посту
                              </p>
                              <a
                                 href={message.params.media_link}
                                 target="_blank"
                                 rel="noreferrer"
                                 style={{
                                    color: 'inherit',
                                    display: 'block',
                                 }}
                              >
                                 {`${message.params.media_link}: `}
                              </a>
                              <p
                                 style={{
                                    color: 'inherit',
                                    fontSize: 'inherit',
                                    fontFamily: 'inherit',
                                 }}
                              >
                                 {getMessageText(message)}
                              </p>
                              <img
                                 src={message?.params?.media_photo || '/assets/chat/clip.svg'}
                                 alt="Пост"
                                 style={{
                                    maxWidth: '150px',
                                    width: '100%',
                                    maxHeight: '150px',
                                    marginTop: '10px',
                                 }}
                                 onError={handleImageLoadingError}
                              />
                           </>
                       ) : (
                           <>
                              {message.link ? (
                                  <a
                                      href={message.link}
                                      target="_blank"
                                      rel="noreferrer"
                                      style={{
                                         color: 'inherit',
                                         display: 'block',
                                      }}
                                  >
                                     {message.link}
                                  </a>
                              ) : (
                                  <></>
                              )}
                              {message.thumb && (
                                  <img
                                      onClick={() => {
                                         setImagePreviewLink(message.thumb)
                                         setImagePreviewModal(true)
                                         setImagePreviewType('image')
                                         setImagePreviewId(message.id)
                                         setImagePreviewThumb(message.thumb)
                                      }}
                                      src={message.thumb}
                                      alt=""
                                      style={{
                                         height: '150px',
                                         width: '150px',
                                         objectFit: 'cover',
                                         cursor: 'pointer',
                                      }}
                                      onError={handleImageLoadingError}
                                  />
                              )}
                              <div>
                                 {getMessageText(message)}
                              </div>
                           </>
                       )}
                    </>
                )}
             </p>
             <span className="mainbar-conversation-message-card-time">
                {convertDate(message.timestamp, true)}{' '}
                {message.is_incoming ? <MessageStatus
                    message={message}
                    messageStatus={messageStatus}
                    autoReadStatus={autoReadStatus}
                    fetchDialogData={fetchDialogData}
                    onClick={handleMessageStatusClick}
                /> : <ErrorDesc message={message} messageStatus={messageStatus}/>}
             </span>
          </ContextMenuTrigger>
          <ContextMenu id={String(message.id)} className="delete-message-pop-up-wrap">
             <MenuItem data={{message: message}} onClick={handleReplyClick} className="delete-message-pop-up">
               <img
                  src={`/assets/chat/reply.svg`}
                  alt=""
                  className="delete-message-pop-up-icon"
               />
               Ответить
             </MenuItem>
             {canRevokeMessage && (
               <MenuItem data={{message: message}} onClick={onHandleRightClick} className="delete-message-pop-up">
                  <img
                     src={`/assets/chat/delete.svg`}
                     alt=""
                     className="delete-message-pop-up-icon"
                  />
                  Удалить
               </MenuItem>
             )}
          </ContextMenu>
       </div>
   )
}

export default Message
