const ChatBotsPlug = () => {
   return (
      <div className="mailing-wrapper">
         <span className="tags-status-settings_subtitle">
            Раздел находится в разработке. Если у вас есть пожелания по этому
            разделу, напишите нам на{' '}
            <a className="template-item_file-ref" href={'support@i2crm.ru'}>
               support@i2crm.ru
            </a>
         </span>
      </div>
   )
}

export default ChatBotsPlug
