import React, { useEffect, useRef } from 'react'
import useState from 'react-usestateref'
import './mainbar.css'
import Previewer from '../modals/Previewer'
import EmojiPicker from './EmojiPicker'
import Dialogs from './Dialogs'
import MessagesService from '../../services/messagesService'
import Loader from '../Loader'
import DialogSystemMessage from '../DialogSystemMessage'

const MainbarConversations = ({
   selectedConversation,
   messages,
   nextMessagesCursor,
   setNextMessagesCursor,
   messagesLoader,
   setMessagesLoader,
   showTemplate,
   setShowTemplate,
   templateMessages,
   setSelectTemplate,
   messagesRef,
   setCommentReplySection,
   commentReplySection,
   channelStatus,
   setMessages,
   showEmoji,
   setShowEmoji,
   setChosenEmoji,
   autoReadStatus,
   userId,
   fetchDialogData,
   setInstagramConvoFilter,
   setUnreadMessagesIds,
   handleMessageStatusClick,
   handleReplyMessageClick,
   selectedRepliedMessage,
   setSelectedRepliedMessage,
   getNextCursorDialogList
}) => {
   const [imagePreviewModal, setImagePreviewModal] = useState(false)
   const [imagePreviewLink, setImagePreviewLink, imagePreviewLinkRef] =
      useState(null)
   const [imagePreviewType, setImagePreviewType, imagePreviewTypeRef] =
      useState(null)
   const [imagePreviewId, setImagePreviewId, imagePreviewIdRef] = useState(null)
   const [imagePreviewThumb, setImagePreviewThumb] = useState(null)
   const [disableScroll, setDisableScroll] = useState(false)
   const shouldFetchMessages = useRef(false)

   useEffect(() => {
      if (shouldFetchMessages.current) {
         setMessagesLoader(true)
         let instagramConvoFilter = ''
         if (channelStatus.value) {
            if (channelStatus.value === 1) {
               instagramConvoFilter = ''
               setInstagramConvoFilter('all')
            }
            if (channelStatus.value === 2) {
               instagramConvoFilter = 'direct'
               setInstagramConvoFilter('direct')
            }
            if (channelStatus.value === 3) {
               instagramConvoFilter = 'comment'
               setInstagramConvoFilter('comment')
            }
         }

         const queryParams = {
            id: selectedConversation,
            filter: instagramConvoFilter
         }

         MessagesService
            .fetchMessages(queryParams)
            .then(data => {
               setMessages(data.data.messages)
               setNextMessagesCursor(data.data.next_cursor)
            })
            .catch((err) => {
               console.log(err)
            })
            .finally(() => {
               setMessagesLoader(false)
            })
      } else {
         shouldFetchMessages.current = true
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [channelStatus.value])

   const handleScroll = async (e) => {
      if (
         e.target.clientHeight - e.target.scrollHeight + 35 >
         e.target.scrollTop && !disableScroll
      ) {
         setDisableScroll(true)
         await getNextCursorDialogList()
         setDisableScroll(false)
      }
   }

   const handleTemplateSelect = (template) => {
      setSelectTemplate(template)
      setShowTemplate(false)
   }

   useEffect(() => {
      showTemplate && setShowEmoji(false)
      showEmoji && setShowTemplate(false)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [showEmoji, showTemplate])

   return messagesLoader ? (
      <Loader />
   ) : (
      <div
         id='messages-list' 
         className="mainbar-conversations"
         onScroll={handleScroll}
      >
         {imagePreviewModal ? (
            <Previewer
               close={() => {
                  setImagePreviewModal(false)
               }}
               imagePreviewModal={imagePreviewModal}
               imagePreviewLink={imagePreviewLink}
               imagePreviewType={imagePreviewType}
               imagePreviewId={imagePreviewId}
               selectedConversation={selectedConversation}
               setImagePreviewLink={setImagePreviewLink}
               setImagePreviewModal={setImagePreviewModal}
               setImagePreviewType={setImagePreviewType}
               setImagePreviewId={setImagePreviewId}
               imagePreviewLinkRef={imagePreviewLinkRef}
               imagePreviewIdRef={imagePreviewIdRef}
               imagePreviewTypeRef={imagePreviewTypeRef}
               imagePreviewThumb={imagePreviewThumb}
               setImagePreviewThumb={setImagePreviewThumb}
            />
         ) : (
            <></>
         )}
         {showTemplate && (
            <div className="mainbar-chat-template-box">
               <div className="mainbar-chat-template-wrapper">
                  {templateMessages.length !== 0 &&
                     templateMessages.map((template, index) => (
                        <div
                           className="mainbar-chat-template"
                           key={index}
                           onClick={() => handleTemplateSelect(template)}
                        >
                           {template.name}
                        </div>
                     ))}
               </div>
            </div>
         )}
         {showEmoji && (
            <div className="mainbar-chat-emoji-box">
               <EmojiPicker setChosenEmoji={setChosenEmoji} />
            </div>
         )}
         <Dialogs
            messages={messages}
            setImagePreviewLink={setImagePreviewLink}
            setImagePreviewModal={setImagePreviewModal}
            setImagePreviewType={setImagePreviewType}
            setCommentReplySection={setCommentReplySection}
            autoReadStatus={autoReadStatus}
            setImagePreviewId={setImagePreviewId}
            setImagePreviewThumb={setImagePreviewThumb}
            fetchDialogData={fetchDialogData}
            setUnreadMessagesIds={setUnreadMessagesIds}
            handleMessageStatusClick={handleMessageStatusClick}
            handleReplyMessageClick={handleReplyMessageClick}
            selectedRepliedMessage={selectedRepliedMessage}
            setSelectedRepliedMessage={setSelectedRepliedMessage}
         />
         <DialogSystemMessage />
      </div>
   )
}

export default MainbarConversations
