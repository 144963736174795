import { useEffect, useState } from 'react'
import Select, { components } from 'react-select'
import MainbarConversations from './MainbarConversations'
import MainbarChat from './MainbarChat'
import './mainbar.css'
import ManagerMainbar from '../manager/ManagerMainbar'
import DragAndDrop from '../utils/DragAndDrop'
import DialogSettings from '../settings/DialogSettings'
import ChatBots from '../settings/ChatBots'
import TagsStatus from '../settings/TagsStatus'
import NotificationModal from '../modals/NotificationModal'
import Templates from '../settings/Templates'
import Mailing from '../mailing/Mailing'
import ContactDiscription from './ContactDiscription'
import ChatBotsPlug from '../settings/ChatBotsPlug'
import { notificationsData, tooltipsData } from '../../utils/consts'
import Tooltip from '../Tooltip'

const MainbarHeader = ({
   dialog,
   onDialogTagSelect,
   onDialogStatusSelect,
   setDialog,
   currentUser,
   setSelectTemplate,
   setSelectedConversation,
   allowedTag,
   allowedStatus,
   allowedChannel,
   isManager,
   selectedConversation,
   setDialogs,
   dialogs,
   channelStatus,
   setChannelStatus,
   tag,
   status,
}) => {
   const {
      name: clientName,
      identity: clientPhone
   } = dialog.client
   const username = dialog.dialog_username
   const whatsappDialogName = `${clientName} (+${clientPhone}) - ${username}`
   const isWhatsapp = dialog.dialog_channel === "whatsapp"

   const statusList = status.map((item) => {
      return { value: item.id, label: item.name, bg: item.color }
   })

   const tagList = tag.map((item) => {
      return { 
         value: item.id, 
         label: item.name,
         isSelected: dialog.dialog_tag.some(tag => tag.id === item.id) 
      }
   })

   const [notification, setNotification] = useState({
      isVisible: false,
      ...notificationsData.default
   })

   const showNotification = (notificationData) => {
      setNotification(notification => ({
         isVisible: !notification.isVisible,
         ...notificationData
      }))
   }

   const closeNotification = () => setNotification(notification => ({ ...notification, isVisible: false }))

   const selectedDialogStatus = (
      dialog.dialog_status !== null
         ? {
            value: dialog.dialog_status.id,
            label: dialog.dialog_status.name,
            bg: dialog.dialog_status.color,
         }
         : {
            value: 'Не задано',
            label: 'Не задано',
            bg: '#FFFFFF',
         }
   )

   const tagStyles = {
      container: (provided, state) => {
         return {
            ...provided,
            color: '#158CAE',
            fontSize: '12px',
            fontWeight: '400',
            width: '200px',

            minHeight: '0',
            maxHeight: '23px',
         }
      },

      control: (provided, state) => {
         return {
            ...provided,
            color: '#158CAE',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
            borderBottomRightRadius: state.menuIsOpen ? '0' : '8px',
            borderBottomLeftRadius: state.menuIsOpen ? '0' : '8px',
            paddingTop: '0px',
            borderRadius: '0',
            minHeight: '0',
            maxHeight: '23px',
            paddingLeft: '6px',
            cursor: 'pointer',
            border: '0',
            boxShadow: 'none',
         }
      },

      dropdownIndicator: (provided, state) => {
         return {
            ...provided,
            padding: '0',
            paddingLeft: '14px',
            color: '#158CAE',
            transform: 'rotate(180deg)',
         }
      },

      indicatorSeparator: (provided, state) => {
         return {
            ...provided,
            display: 'none',
         }
      },

      valueContainer: (provided, state) => {
         return {
            ...provided,
            color: '#158CAE',
            height: '23px',
         }
      },

      singleValue: (provided, state) => {
         return {
            ...provided,
            color: '#158CAE',
         }
      },
      menu: (base) => ({
         ...base,
         marginTop: '0',
         marginBottom: '0',
         boxShadow: 'none',
         borderRadius: '0',
         borderBottomLeftRadius: '6px',
         borderBottomRightRadius: '6px',
      }),

      menuList: (base) => ({
         ...base,
         borderBottomLeftRadius: '6px',
         borderBottomRightRadius: '6px',
         backgroundColor: 'white',
         maxHeight: '162px',
         maxWidth: '200px',
         padding: '0',
         '::-webkit-scrollbar': {
            display: 'block',
            width: '4px',
            height: '0px',
         },
         '::-webkit-scrollbar-track': {
            background: 'fff',
         },
         '::-webkit-scrollbar-thumb': {
            background: '#888',
            backgroundColor: '#40B7D9',
         },
      }),

      option: (provided, state) => {
         return {
            ...provided,
            color: '#158CAE',
            backgroundColor: state.data.isSelected && 'lightblue',
            ':hover': {
               backgroundColor: 'lightblue',
               color: 'white'
            },
            borderRadius: '6px',
            marginBottom: '4px',
            ':last-child': {
               marginBottom: '0'
            },
            width: '100%',
            height: '23px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            cursor: 'pointer',
         }
      },
   }

   const messageChannelList = [
      { value: 1, label: 'Все сообщения' },
      { value: 2, label: 'Только директ' },
      { value: 3, label: 'Только комментарии' },
   ]

   const getDialogStatus = () => {
      // console.log(
      //    'getDialogStatus',
      //    dialogStatus.filter(
      //       (dialog) => dialog.dialog_id === selectedConversation
      //    )
      // )
      return dialog
   }

   const getDialogTag = () => {
      // console.log('dialog', dialog)
      // console.log(
      //    'selected',
      //    dialogStatus.filter(
      //       (dialog) => dialog.dialog_id === selectedConversation
      //    )[0]
      // )
      return dialog
   }

   const selectedDialogData = getDialogStatus()

   const statusStyles = {
      container: (provided, state) => {
         return {
            ...provided,
            color: '#fff',
            fontSize: '12px',
            fontWeight: '400',
            width: '220px',
            minHeight: '0',
            maxHeight: '23px',
         }
      },

      control: (provided, state) => {
         return {
            ...provided,
            color: '#fff',
            backgroundColor: selectedDialogStatus.bg,
            borderRadius: '8px',
            border: 'none',
            boxShadow: '0',
            minHeight: '0',
            maxHeight: '23px',
            paddingLeft: '6px',
            cursor: 'pointer',
         }
      },

      valueContainer: (provided, state) => {
         return {
            ...provided,
            color: '#fff',
            height: '23px',
         }
      },

      dropdownIndicator: (provided, state) => {
         return {
            ...provided,
            padding: '0',
            paddingLeft: '14px',
            color: '#fff',
            transform: 'rotate(180deg)',
         }
      },

      indicatorSeparator: (provided, state) => {
         return {
            ...provided,
            display: 'none',
         }
      },

      singleValue: (provided, state) => {
         return {
            ...provided,
            color: '#fff',
         }
      },

      menu: (base) => ({
         ...base,
         marginTop: '0',
         marginBottom: '0',
         boxShadow: '0',
         borderRadius: '0',
         borderBottomLeftRadius: '6px',
         borderBottomRightRadius: '6px',
      }),

      menuList: (base) => ({
         ...base,
         borderBottomLeftRadius: '6px',
         borderBottomRightRadius: '6px',
         height: '162px',
         width: '220px',
         '::-webkit-scrollbar': {
            display: 'block',
            width: '4px',
            height: '0px',
         },
         '::-webkit-scrollbar-track': {
            background: 'fff',
         },
         '::-webkit-scrollbar-thumb': {
            background: '#888',
            backgroundColor: '#40B7D9',
         },
      }),
      option: (provided, state) => {
         return {
            ...provided,
            color: '#fff',
            backgroundColor: state.data.bg,
            borderRadius: '6px',
            marginBottom: '4px',
            height: '23px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            cursor: 'pointer',
         }
      },
   }

   const handleConversationTagClick = (event) => {
      if (dialog.read_only && isManager) {
         return showNotification(notificationsData.readOnly)
      }
      if (dialog.dialog_tag.find((tag) => tag.id === event.value))
         return showNotification(notificationsData.tagAlreadyAttached)
      onDialogTagSelect(event)
   }

   const handleConversationStatusClick = (status) => {
      if (dialog.read_only && isManager) {
         return showNotification(notificationsData.readOnly)
      }
      onDialogStatusSelect(dialog.dialog_id, status)
   }

   const { Control } = components

   const CustomControl = ({ children, ...props }) => {
      return (
         <Control {...props}>
            <div className='custom-select-control-body'>
               {children}
               <Tooltip
                  className='custom-select-control-body__tooltip'
                  text={props.selectProps.tooltipText}
                  position='bottom'
               />
            </div>
         </Control>
      )
   }

   return (
      <div className="mainbar-header">
         <NotificationModal
            isVisible={notification.isVisible}
            title={notification.title}
            text={notification.text}
            ok={closeNotification}
         />
         {selectedDialogData ? (
            <div className="mainbar-header-wrapper">
               <div className="mainbar-header-conversation-control">
                  <span className="mainbar-header-conversation-username">
                     {isWhatsapp ? whatsappDialogName : dialog.dialog_name}
                  </span>
                  <div className="mainbar-header-conversation-control-buttons">
                     <Select
                        components={{ Control: CustomControl }}
                        isDisabled={
                           dialog
                              ? dialog.dialog_channelStatus !== 'active'
                              : null
                        }
                        menuPlacement="auto"
                        styles={statusStyles}
                        isSearchable={false}
                        options={statusList}
                        onChange={(event) =>
                           handleConversationStatusClick(event)
                        }
                        value={selectedDialogStatus}
                        tooltipText={tooltipsData.chat.statusList}
                     />
                     <Select
                        components={{ Control: CustomControl }}
                        isDisabled={
                           dialog
                              ? dialog.dialog_channelStatus !== 'active'
                              : null
                        }
                        maxMenuHeight={162}
                        menuPlacement="auto"
                        styles={tagStyles}
                        isSearchable={false}
                        options={tagList}
                        onChange={(event) => handleConversationTagClick(event)}
                        value={
                           dialog.dialog_tag.length > 0
                              ? {
                                   value: dialog.dialog_tag[
                                      dialog.dialog_tag.length - 1
                                   ].id,
                                   label: dialog.dialog_tag[
                                      dialog.dialog_tag.length - 1
                                   ].name,
                                }
                              : {
                                   value: 'Не задано',
                                   label: 'Не задано',
                                }
                        }
                        tooltipText={tooltipsData.chat.tagList}
                     />
                     {selectedDialogData.dialog_channel === 'instagram' && (
                        <Select
                           isSearchable={false}
                           className="mainbar-header-conversation-channel-container"
                           classNamePrefix="mainbar-header-conversation-channel"
                           options={messageChannelList}
                           onChange={(event) => setChannelStatus(event)}
                           value={channelStatus}
                        />
                     )}
                  </div>
               </div>
            </div>
         ) : (
            <div className="mainbar-header-wrapper"></div>
         )}
      </div>
   )
}

const Mainbar = ({
   currentContact,
   setCurrentContact,
   dialog,
   handleDialogTagSelect,
   handleDialogStatusSelect,
   setDialog,
   currentUser,
   templates,
   setTemplates,
   allowedTag,
   allowedStatus,
   allowedChannel,
   isManager,
   isMainbarTablet,
   setSelectedConversation,
   selectedConversation,
   setDialogs,
   dialogs,
   messages,
   setMessages,
   nextMessagesCursor,
   setNextMessagesCursor,
   messagesLoader,
   setMessagesLoader,
   channelStatus,
   setChannelStatus,
   sidebarToggle,
   managerCardClick,
   addManagerPage,
   messagesRef,
   autoReadStatus,
   settingsToggle,
   setAutoReadStatus,
   channelList,
   userId,
   fetchDialogData,
   setInstagramConvoFilter,
   toggleSidebarView,
   tag,
   setTag,
   addTag,
   deleteTag,
   editTag,
   status,
   setStatus,
   addStatus,
   deleteStatus,
   editStatus,
   messageText,
   onMessageTextChange,
   selectTemplate,
   setSelectTemplate,
   setSidebarToggle,
   setUnreadMessagesIds,
   handleMessageStatusClick,
   handleEditManagerFormSubmit,
   handleToggleManagerActivation,
   handleDeleteManager,
   handleReplyMessageClick,
   selectedRepliedMessage,
   setSelectedRepliedMessage,
   getNextCursorDialogList
}) => {
   const [showTemplate, setShowTemplate] = useState(false)
   const [templateMessages, setTemplateMessages] = useState([])
   const [commentReplySection, setCommentReplySection] = useState(null)
   const [showEmoji, setShowEmoji] = useState(false)
   const [chosenEmoji, setChosenEmoji] = useState(null)
   const [dragActive, setDragActive] = useState(false)
   const [uploadFileList, setUploadFileList] = useState([])

   const [showChat, setShowChat] = useState(false)

   const handleReplySectionClose = () => {
      setCommentReplySection(null)
   }

   useEffect(() => {
      if (selectedConversation) setShowChat(false)
   }, [selectedConversation])

   const handleDragOver = () => {
      setDragActive(true)
   }

   const handleDrop = () => {
      setDragActive(false)
   }

   useEffect(() => {
      window.addEventListener('dragover', handleDragOver)

      window.addEventListener('drop', handleDrop)
      return () => {
         window.removeEventListener('dragover', handleDragOver)

         window.removeEventListener('drop', handleDrop)
      }
   }, [])

   useEffect(() => {
      setCommentReplySection(null)
   }, [channelStatus])

   const renderSettingsSwitch = (param) => {
      switch (param) {
         case 1:
            return (
               // <ChatBots
               //    channelList={channelList}
               //    toggleSidebarView={toggleSidebarView}
               // />
               <ChatBotsPlug />
            )
         case 2:
            return (
               <Templates templates={templates} setTemplates={setTemplates} />
            )
         case 3:
            return (
               <TagsStatus
                  isMainbarTablet={isMainbarTablet}
                  tag={tag}
                  setTag={setTag}
                  addTag={addTag}
                  deleteTag={deleteTag}
                  editTag={editTag}
                  status={status}
                  setStatus={setStatus}
                  addStatus={addStatus}
                  deleteStatus={deleteStatus}
                  editStatus={editStatus}
               />
            )
         case 4:
            return (
               <DialogSettings
                  autoReadStatus={autoReadStatus}
                  setAutoReadStatus={setAutoReadStatus}
               />
            )
         default:
            return <></>
      }
   }

   return sidebarToggle === 5 ? (
      <Mailing
         currentUser={currentUser}
         tag={tag}
         allowedTag={allowedTag}
         status={status}
         allowedStatus={allowedStatus}
         channelList={channelList}
         allowedChannel={allowedChannel}
      />
   ) : sidebarToggle === 2 ? (
      <>
         {showChat ? (
            <>
               <div className="mainbar">
                  <div
                     style={{
                        display: isMainbarTablet ? 'block' : 'none',
                        position: 'absolute',
                        top: '20px',
                        left: '5px',
                        zIndex: '1111',
                        height: '20px',
                        width: '20px',
                     }}
                     className="mainbar-sidebar-toggle-left-button"
                     onClick={() => setSelectedConversation(0)}
                  >
                     <img
                        src={`/assets/controls/chevron-left.svg`}
                        alt=""
                        style={{ width: '100%', height: '100%' }}
                     />
                  </div>
                  {selectedConversation ? (
                     <>
                        {dragActive ? (
                           <DragAndDrop
                              selectedConversation={selectedConversation}
                              messages={messages}
                              setMessages={setMessages}
                              newMessagesRef={messagesRef}
                           />
                        ) : (
                           <></>
                        )}
                        <MainbarHeader
                           dialog={dialog}
                           onDialogTagSelect={handleDialogTagSelect}
                           onDialogStatusSelect={handleDialogStatusSelect}
                           setDialog={setDialog}
                           setSelectedConversation={setSelectedConversation}
                           allowedStatus={allowedStatus}
                           allowedTag={allowedTag}
                           allowedChannel={allowedChannel}
                           isManager={isManager}
                           status={status}
                           tag={tag}
                           selectedConversation={selectedConversation}
                           setDialogs={setDialogs}
                           dialogs={dialogs}
                           channelStatus={channelStatus}
                           setChannelStatus={setChannelStatus}
                           currentUser={currentUser}
                        />
                        <MainbarConversations
                           selectedConversation={selectedConversation}
                           messages={messages}
                           nextMessagesCursor={nextMessagesCursor}
                           setNextMessagesCursor={setNextMessagesCursor}
                           messagesLoader={messagesLoader}
                           setMessagesLoader={setMessagesLoader}
                           showTemplate={showTemplate}
                           setShowTemplate={setShowTemplate}
                           templateMessages={templateMessages}
                           setSelectTemplate={setSelectTemplate}
                           messagesRef={messagesRef}
                           setCommentReplySection={setCommentReplySection}
                           commentReplySection={commentReplySection}
                           channelStatus={channelStatus}
                           setMessages={setMessages}
                           showEmoji={showEmoji}
                           setShowEmoji={setShowEmoji}
                           setChosenEmoji={setChosenEmoji}
                           autoReadStatus={autoReadStatus}
                           uploadFileList={setUploadFileList}
                           userId={userId}
                           fetchDialogData={fetchDialogData}
                           setInstagramConvoFilter={setInstagramConvoFilter}
                           setUnreadMessagesIds={setUnreadMessagesIds}
                           handleMessageStatusClick={handleMessageStatusClick}
                           handleReplyMessageClick={handleReplyMessageClick}
                           selectedRepliedMessage={selectedRepliedMessage}
                           setSelectedRepliedMessage={setSelectedRepliedMessage}
                           getNextCursorDialogList={getNextCursorDialogList}
                        />
                        <MainbarChat
                           dialog={dialog}
                           currentUser={currentUser}
                           isManager={isManager}
                           templates={templates}
                           dialogs={dialogs}
                           selectedConversation={selectedConversation}
                           setMessages={setMessages}
                           messages={messages}
                           setShowTemplate={setShowTemplate}
                           setTemplateMessages={setTemplateMessages}
                           selectTemplate={selectTemplate}
                           messagesRef={messagesRef}
                           commentReplySection={commentReplySection}
                           setCommentReplySection={setCommentReplySection}
                           showEmoji={showEmoji}
                           setShowEmoji={setShowEmoji}
                           chosenEmoji={chosenEmoji}
                           uploadFileList={uploadFileList}
                           setUploadFileList={setUploadFileList}
                           toggleSidebarView={toggleSidebarView}
                           setSelectTemplate={setSelectTemplate}
                           messageText={messageText}
                           onMessageTextChange={onMessageTextChange}
                           handleReplySectionClose={handleReplySectionClose}
                        />
                     </>
                  ) : (
                     <></>
                  )}
               </div>
            </>
         ) : (
            <ContactDiscription
               dialogs={dialogs}
               setDialogs={setDialogs}
               setSelectedConversation={setSelectedConversation}
               currentContact={currentContact}
               setCurrentContact={setCurrentContact}
               setShowChat={setShowChat}
            />
         )}
      </>
   ) : sidebarToggle === 5 ? (
      <Mailing
         currentUser={currentUser}
         tag={tag}
         allowedTag={allowedTag}
         status={status}
         allowedStatus={allowedStatus}
         channelList={channelList}
         allowedChannel={allowedChannel}
      />
   ) : sidebarToggle === 4 ? (
      renderSettingsSwitch(settingsToggle)
   ) : sidebarToggle === 3 ? (
      <>
         <div className="mainbar">
            <div
               style={{
                  display: isMainbarTablet ? 'block' : 'none',
                  position: 'absolute',
                  top: '15px',
                  left: '10px',
                  zIndex: '1111',
                  height: '25px',
                  width: '25px',
               }}
               className="mainbar-sidebar-toggle-left-button"
               onClick={() => {
                  setSelectedConversation(0)
                  setSidebarToggle(0)
               }}
            >
               <img
                  src={`/assets/controls/chevron-left.svg`}
                  alt=""
                  style={{ filter: 'invert(0.7)' }}
               />
            </div>
            <ManagerMainbar
               tag={tag}
               status={status}
               channelList={channelList}
               managerCardClick={managerCardClick}
               addManagerPage={addManagerPage}
               handleEditManagerFormSubmit={handleEditManagerFormSubmit}
               handleToggleManagerActivation={handleToggleManagerActivation}
               handleDeleteManager={handleDeleteManager}
            />
         </div>
      </>
   ) : (
      <div className="mainbar">
         <div
            style={{
               display: isMainbarTablet ? 'block' : 'none',
               position: 'absolute',
               top: '20px',
               left: '5px',
               zIndex: '1111',
               height: '20px',
               width: '20px',
            }}
            className="mainbar-sidebar-toggle-left-button"
            onClick={() => setSelectedConversation(0)}
         >
            <img
               src={`/assets/controls/chevron-left.svg`}
               alt=""
               style={{ width: '100%', height: '100%' }}
            />
         </div>
         {selectedConversation ? (
            <>
               {dragActive ? (
                  <DragAndDrop
                     selectedConversation={selectedConversation}
                     messages={messages}
                     setMessages={setMessages}
                     messagesRef={messagesRef}
                  />
               ) : (
                  <></>
               )}
               <MainbarHeader
                  dialog={dialog}
                  onDialogTagSelect={handleDialogTagSelect}
                  onDialogStatusSelect={handleDialogStatusSelect}
                  setDialog={setDialog}
                  setSelectedConversation={setSelectedConversation}
                  allowedStatus={allowedStatus}
                  allowedTag={allowedTag}
                  allowedChannel={allowedChannel}
                  isManager={isManager}
                  status={status}
                  tag={tag}
                  selectedConversation={selectedConversation}
                  setDialogs={setDialogs}
                  dialogs={dialogs}
                  channelStatus={channelStatus}
                  setChannelStatus={setChannelStatus}
                  currentUser={currentUser}
               />
               <MainbarConversations
                  selectedConversation={selectedConversation}
                  messages={messages}
                  nextMessagesCursor={nextMessagesCursor}
                  setNextMessagesCursor={setNextMessagesCursor}
                  messagesLoader={messagesLoader}
                  setMessagesLoader={setMessagesLoader}
                  showTemplate={showTemplate}
                  setShowTemplate={setShowTemplate}
                  templateMessages={templateMessages}
                  setSelectTemplate={setSelectTemplate}
                  messagesRef={messagesRef}
                  setCommentReplySection={setCommentReplySection}
                  commentReplySection={commentReplySection}
                  channelStatus={channelStatus}
                  setMessages={setMessages}
                  showEmoji={showEmoji}
                  setShowEmoji={setShowEmoji}
                  setChosenEmoji={setChosenEmoji}
                  autoReadStatus={autoReadStatus}
                  uploadFileList={setUploadFileList}
                  userId={userId}
                  fetchDialogData={fetchDialogData}
                  setInstagramConvoFilter={setInstagramConvoFilter}
                  setUnreadMessagesIds={setUnreadMessagesIds}
                  handleMessageStatusClick={handleMessageStatusClick}
                  handleReplyMessageClick={handleReplyMessageClick}
                  selectedRepliedMessage={selectedRepliedMessage}
                  setSelectedRepliedMessage={setSelectedRepliedMessage}
                  getNextCursorDialogList={getNextCursorDialogList}
               />
               <MainbarChat
                  dialog={dialog}
                  currentUser={currentUser}
                  isManager={isManager}
                  templates={templates}
                  dialogs={dialogs}
                  selectedConversation={selectedConversation}
                  setMessages={setMessages}
                  messages={messages}
                  setShowTemplate={setShowTemplate}
                  setTemplateMessages={setTemplateMessages}
                  selectTemplate={selectTemplate}
                  messagesRef={messagesRef}
                  commentReplySection={commentReplySection}
                  setCommentReplySection={setCommentReplySection}
                  showEmoji={showEmoji}
                  setShowEmoji={setShowEmoji}
                  chosenEmoji={chosenEmoji}
                  uploadFileList={uploadFileList}
                  setUploadFileList={setUploadFileList}
                  toggleSidebarView={toggleSidebarView}
                  setSelectTemplate={setSelectTemplate}
                  messageText={messageText}
                  onMessageTextChange={onMessageTextChange}
                  handleReplySectionClose={handleReplySectionClose}
               />
            </>
         ) : (
            <></>
         )}
      </div>
   )
}

export default Mainbar
