import React, { useState } from 'react'
import axios from 'axios'
import './dialogSettings.css'

const DialogSettings = ({
   autoReadStatus,
   setAutoReadStatus,
}) => {
   const [autoReadToggle, setAutoReadToggle] = useState(autoReadStatus)

   const activeToggleButton = {
      backgroundColor: '#00ACEF',
      marginLeft: 'auto',
   }

   const inActiveToggleButton = {
      backgroundColor: '#CCCCCC',
      marginRight: 'auto',
   }

   const handleAutoReadToggle = () => {
      setAutoReadToggle((prev) => !prev)
      let queryString = autoReadStatus ? 'false' : 'true'
      axios({
         method: 'post',
         url: `/api/v1/dialog/auto-read?status=${queryString}`,
         withCredentials: true,
      })
         .then(function (response) {
            //handle success
            if (response.data.error === false) {
               setAutoReadStatus((prev) => !prev)
               window.location.reload(false)
            } else {
               window.alert('Произошла ошибка!')
               setAutoReadToggle((prev) => !prev)
            }
         })
         .catch(function (response) {
            //handle error
            console.log(response)
            window.alert('Произошла ошибка!')
            setAutoReadToggle((prev) => !prev)
         })
   }

   return (
      <div className="dialog-settings">
         <div className="dialog-settings-wrapper">
            <div style={{ display: 'grid', gridTemplateColumns: '215px auto' }}>
               <span className="manager-permission-field-name">
                  Автопрочтение диалогов
               </span>
               <div
                  className="manager-permission-controller"
                  onClick={handleAutoReadToggle}
               >
                  <div
                     className="manager-permission-toggle-button"
                     style={
                        autoReadToggle
                           ? activeToggleButton
                           : inActiveToggleButton
                     }
                  ></div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default DialogSettings
