import React from 'react'
import './ReplyMessage.css'
import { messageTypes } from '../../../utils/consts'

const ReplyMessage = ({ message, onClick }) => {
   const { id, type, author, text, link, thumb, params } = message
   const image = thumb || params?.media_photo || (type === 'image' ? link : '')
   const title = author?.name || author?.username || ''
   const replyText = text || messageTypes[type]

   const handleClick = () => {
      onClick(id)
   }
   
   const handleImageLoadingError = (e) => {
      e.target.src = '/assets/chat/clip.svg'
   }

   return (
      <div 
         className="reply-message"
         onClick={handleClick}   
      >
         {image && (
            <div className='reply-message__image'>
               <img 
                  src={image} 
                  alt="" 
                  onError={handleImageLoadingError} 
               />
            </div>
         )}
         <div className="reply-message__info">
            <p className="reply-message__title">
               {title}
            </p>
            <p className="reply-message__text">
               {replyText}
            </p>
         </div>
      </div>
   )
}

export default ReplyMessage