import $axios from '../http'

class ManagersService {
   static updateManager = async (id, body) => {
      const { data } = await $axios.post(`manager/update?managerId=${id}`, body)
      return data
   }

   static activate = async (id) => {
      const { data } = await $axios.put(`manager/activate?managerId=${id}`)
      return data
   }

   static deactivate = async (id) => {
      const { data } = await $axios.put(`manager/deactivate?managerId=${id}`)
      return data
   }

   static delete = async (id) => {
      const { data } = await $axios.delete(`manager/delete?managerId=${id}`)
      return data
   }
}

export default ManagersService