import { useCallback, useEffect, useMemo } from 'react'
import useState from 'react-usestateref'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import debounce from 'lodash.debounce'
import MessengerWS from '../services/MessengerWS'
import { Sidebar } from '../components/sidebar/Sidebar'
import Mainbar from '../components/mainbar/Mainbar'
import useWindowDimensions from '../components/utils/WindowDimensions'
import './messenger.css'
import Navbar from '../components/navbar/Navbar'
import { 
   getNormalizedDialog, 
   mapDialogsSearchToQuery, 
   mapDialogsSearchToFilter, 
   mapDialogsFilterToQuery, 
   mapDialogsFilterToSearch
} from '../utils'
import { DEFAULT_DIALOGS_FILTER } from '../utils/consts/defaultDialogsParams'
import Notifications from '../services/Notifications'
import { useDisplayNotificationsQtyInTitle } from '../hooks'
import DialogsService from '../services/dialogsService'
import { useDispatch } from 'react-redux'
import { logout } from '../store/slices/auth/thunk-creators'
import Onboarding from '../components/Onboarding'
import ManagersService from '../services/managersService'
import { managerStatuses, userEvents, socketEventTypes } from '../utils/consts'
import AuthService from '../services/authService'
import MessagesService from '../services/messagesService'

const Messenger = ({
   currentUser,
   isUserAuthorized,
   setIsUserAuthorized,
   userName,
   autoReadStatus,
   setAutoReadStatus,
   isManager,
   userId,
   setUserId,
   allowedStatus,
   allowedTag,
   allowedChannel,
   disableExit,
}) => {
   const [sidebarToggle, setSidebarToggle] = useState(1)
   const [toggleSidebarView, setToggleSidebarView, toggleSidebarViewRef] =
      useState(true)
   const [dialogs, setDialogs, dialogsRef] = useState([])
   const [dialogsLoading, setDialogsLoading] = useState(false)
   const [dialogsSearchValue, setDialogsSearchValue] = useState('')
   const [dialogsUnreadOnly, setDialogsUnreadOnly] = useState(false)
   const [dialogsFilter, setDialogsFilter] = useState(DEFAULT_DIALOGS_FILTER)
   const [shouldUpdateQuery, setShouldUpdateQuery] = useState(false)
   const [shouldFetchDialogs, setShouldFetchDialogs] = useState(false)
   const [disableDialogsScroll, setDisableDialogsScroll] = useState(false)
   const [messages, setMessages, messagesRef] = useState([])
   const [unreadMessagesIds, setUnreadMessagesIds] = useState([])
   const [nextMessagesCursor, setNextMessagesCursor] = useState(null)
   const [messagesLoader, setMessagesLoader] = useState(true)
   const [channelStatus, setChannelStatus] = useState({
      value: 1,
      label: 'Все сообщения',
   })
   const [addManagerPage, setAddManagerPage] = useState(0)
   const [managerCardClick, setManagerCardClick] = useState(0)
   const [
      selectedConversation,
      setSelectedConversation,
      selectedConversationRef,
   ] = useState(0)
   const [dialog, setDialog] = useState(null)
   const saveDialog = (id) => {
      setDialog(id)
   }

   const { width } = useWindowDimensions()
   const [newContactInitiationParams, setNewContactInitiationParams] =
      useState(null)
   const [showAddContactModal, setShowAddContactModal] = useState(false)
   const [settingsToggle, setSettingsToggle] = useState(0)
   const [messengerLoader, setMessengerLoader] = useState(false)
   const [channelList, setChannelList] = useState([])
   const [, setInstagramConvoFilter, instagramConvoFilterRef] = useState(null)
   const [currentContact, setCurrentContact] = useState(null)

   const [templates, setTemplates] = useState([])

   const [tag, setTag] = useState([])
   const [status, setStatus] = useState([])
   const [messageText, setMessageText] = useState('')
   const [selectTemplate, setSelectTemplate] = useState({})
   const [managerList, setManagerList] = useState(null)
   const [managers, setManagers] = useState(null)
   const [managerSidebarLoader, setManagerSidebarLoader] = useState(true)

   const userOnboarding = currentUser?.user_events.find(event => (
      event.code === userEvents.onBoarding.code
   ))
   const isOnboardingCompleted = [
      userEvents.onBoarding.values.skipped,
      userEvents.onBoarding.values.finished
   ].includes(userOnboarding?.value)

   const location = useLocation()
   const navigate = useNavigate()
   const dispatch = useDispatch()

   const handleMessageTextChange = useCallback((value) => setMessageText(value), [])

   const [handleGettingNewNotification] = useDisplayNotificationsQtyInTitle()

   const handleLogout = async () => {
      await dispatch(logout())
   }

   const handleSearchChange = useMemo(() => debounce((searchValue) => {
      setDialogsFilter(filter => ({
         ...filter,
         searchValue,
         next_cursor: null
      }))
      setDialogsSearchValue(searchValue)
   }, 500), [])

   const handleUnreadOnlyChange = useMemo(() => debounce((value) => {
      setDialogsFilter(filter => ({
         ...filter,
         unreadOnly: value,
         next_cursor: null
      }))
      setDialogsUnreadOnly(value)
   }, 300), [])

   useEffect(() => {
      handleMessageTextChange('')
      setSelectTemplate({})
   }, [selectedConversation])

   useEffect(() => {
      if (selectTemplate.text) {
         handleMessageTextChange(selectTemplate.text)
      } else {
         handleMessageTextChange('')
      }
   }, [selectTemplate])

   useEffect(() => {
      axios({
         method: 'get',
         url: `/api/v1/template/get?`,
         withCredentials: true,
      })
         .then((response) => {
            setTemplates(
               response.data.data.sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                     return -1
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                     return 1
                  }
               })
            )
         })
         .catch((err) => {
            console.log(err)
         })
   }, [])

   const addTag = (body) => {
      var bodyFormData = new FormData()
      bodyFormData.append('name', body.name)

      axios({
         method: 'post',
         url: `/api/v1/tag/add`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag([...tag, response.data.data])
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const deleteTag = (id) => {
      axios({
         method: 'delete',
         url: `/api/v1/tag/delete?id=` + id,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag((prevState) => {
                  return prevState.filter((tag) => {
                     return tag.id !== id
                  })
               })
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const editTag = (body) => {
      var bodyFormData = new FormData()
      bodyFormData.append('id', body.id)
      bodyFormData.append('name', body.name)

      axios({
         method: 'post',
         url: `/api/v1/tag/update`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag((prevState) => {
                  return prevState.map((tag) => {
                     if (tag.id !== body.id) return tag
                     return { ...tag, name: body.name }
                  })
               })
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const addStatus = (body) => {
      var bodyFormData = new FormData()
      bodyFormData.append('name', body.name)
      bodyFormData.append('color', body.color)

      axios({
         method: 'post',
         url: `/api/v1/dialog-step/add`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setStatus([...status, response.data.data])
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const deleteStatus = (id) => {
      axios({
         method: 'delete',
         url: `/api/v1/dialog-step/delete?id=` + id,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setStatus((prevState) => {
                  return prevState.filter((status) => {
                     return status.id !== id
                  })
               })
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   const editStatus = (body) => {
      var bodyFormData = new FormData()
      bodyFormData.append('id', body.id)
      bodyFormData.append('name', body.name)
      bodyFormData.append('color', body.color)

      axios({
         method: 'post',
         url: `/api/v1/dialog-step/update`,
         data: bodyFormData,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setStatus((prevState) => {
                  return prevState.map((status) => {
                     if (status.id !== body.id) return status
                     return { ...status, name: body.name, color: body.color }
                  })
               })
            }
         })
         .catch((response) => {
            window.alert(response)
         })
   }

   useEffect(() => {
      axios({
         method: 'get',
         url: `/api/v1/tag/get`,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setTag([...tag, ...response.data.data])
            }
         })
         .catch((err) => {
            console.log(err)
         })
   }, [])

   useEffect(() => {
      axios({
         method: 'get',
         url: `/api/v1/dialog-step/get`,
         withCredentials: true,
      })
         .then((response) => {
            if (response.data.error) {
               window.alert(response.data.data.error)
            } else {
               setStatus([...status, ...response.data.data])
            }
         })
         .catch((err) => {
            console.log(err)
         })
   }, [])

   const fetchDialogData = async (query) => {
      try {
         const data = await DialogsService.fetchDialogs(query)
         const normalizedDialogs = data.data.dialogs.map((dialog) => getNormalizedDialog(dialog))
         setDialogs(dialogs => [...dialogs, ...normalizedDialogs])
         setDialogsFilter(filter => ({
            ...filter,
            next_cursor: data.data.next_cursor
         }))
      } catch (err) {
         console.log(err)
      }
   }

   const fetchDialogsFirstPortion = async (query) => {
      setDialogsLoading(true)
      await fetchDialogData(query)
      setDialogsLoading(false)
   }

   const loadNextSidebarDialogCursor = async (query) => {
      setDisableDialogsScroll(true)
      await fetchDialogData(query)
      setDisableDialogsScroll(false)
   }

   const attachTagToDialog = async (tag) => {
      try {
         const data = await DialogsService.attachTagToDialog(dialog.dialog_id, tag.value)
         if (data.error) {
            window.alert(data.data.error)
         } else {
            setDialogs(dialogs => dialogs.map(dialogItem => {
                  return dialogItem.dialog_id === dialog.dialog_id
                     ? {
                        ...dialogItem,
                        dialog_tag: [
                           ...dialogItem.dialog_tag,
                           { id: tag.value, name: tag.label },
                        ],
                     }
                     : dialogItem
            }))
            setDialog(dialog => ({
               ...dialog,
               dialog_tag: [...dialog.dialog_tag, { id: tag.value, name: tag.label }]
            }))
         }
      } catch (err) {
         window.alert(err)
         window.location.reload(false)
      }
   }

   const handleDialogTagSelect = async (tag) => {
      await attachTagToDialog(tag)
   }

   const deleteAttachedTagFromDialog = async (tagId, dialogData) => {
      try {
         const data = await DialogsService.deleteAttachedTagFromDialog(dialogData.dialog_id, tagId)
         if (data.error) {
            window.alert(data.data.error)
         } else {
            let tempArray = dialogData.dialog_tag.filter((tag) => tag.id !== tagId)
            setDialogs(dialogs => dialogs.map(dialogItem => {
                  return dialogItem.dialog_id === dialogData.dialog_id
                     ? { ...dialogItem, dialog_tag: tempArray }
                     : dialogItem
            }))
            dialog && setDialog(dialog => ({
               ...dialog,
               dialog_tag: dialog.dialog_tag.filter(tag => tag.id !== tagId)
            }))
         }
      } catch (err) {
         window.alert(err)
         window.location.reload(false)
         return Promise.reject()
      }
   }

   const handleDeleteAttachedTagFromDialog = async (tagId, dialog) => {
      return await deleteAttachedTagFromDialog(tagId, dialog)
   }

   const setDialogStatus = async (dialogId, statusData) => {
      try {
         const data = await DialogsService.setDialogStatus(dialogId, statusData.value)
         if (data.error) {
            window.alert(data.data.error)
            return
         } else {
            if (isManager && !currentUser.read_only_available && !allowedStatus.some(statusId => statusId === status.value)) {
               setSelectedConversation(0)
               setDialog(null)
               return setDialogs(dialogs => dialogs.filter(dialogItem => dialogItem.dialog_id !== dialog.dialog_id))
            }
            setDialogs(dialogs => dialogs.map(dialogItem => (
               dialogItem.dialog_id === dialogId
                  ? {
                     ...dialogItem,
                     dialog_status: {
                        id: statusData.value,
                        name: statusData.label,
                        color: statusData.bg,
                     },
                     read_only: true
                    }
                  : dialogItem
            )))
            setDialog(dialog => ({
               ...dialog,
               dialog_status: {
                  id: statusData.value,
                  name: statusData.label,
                  color: statusData.bg,
               },
               read_only: true
            }))
         }
      } catch (err) {
         window.alert(err)
         window.location.reload(false)
      }
   }

   const handleDialogStatusSelect = async (dialogId, statusData) => {
      await setDialogStatus(dialogId, statusData)
   }

   const handleDialogScroll = (e) => {
      if (
         e.target.scrollHeight - e.target.scrollTop <=
         e.target.clientHeight + 35 && !disableDialogsScroll 
      ) {
         if (dialogsFilter.next_cursor) {
            const query = mapDialogsFilterToQuery(dialogsFilter)
            loadNextSidebarDialogCursor(query)
         }
      }
   }

   useEffect(() => {
      setChannelStatus({
         value: 1,
         label: 'Все сообщения',
      })
   }, [selectedConversation])

   const fetchMessages = async () => {
      try {
         setMessagesLoader(true)
         let instagramConvoFilter = ''
         if (channelStatus.value) {
            switch (channelStatus.value) {
               case 3:
                  instagramConvoFilter = '&filter=comment'
                  setInstagramConvoFilter('comment')
                  break;
               case 2:
                  instagramConvoFilter = '&filter=direct'
                  setInstagramConvoFilter('direct')
                  break;
               default:
                  instagramConvoFilter = ''
                  setInstagramConvoFilter('all')
                  break;
            }
         }
         const { data } = await axios.get(`/api/v1/message/get?dialog=` + selectedConversation + instagramConvoFilter, {
            withCredentials: true,
         })
         setMessages(data.data.messages)
         setNextMessagesCursor(data.data.next_cursor)   
      } catch (err) {
         console.log(err)
      } finally {
         setMessagesLoader(false) 
      }   
   }

   const getNextCursorDialogList = async (cursor) => {
      let instagramConvoFilter = ''
      if (channelStatus.value) {
         if (channelStatus.value === 1) instagramConvoFilter = ''
         if (channelStatus.value === 2) instagramConvoFilter = 'direct'
         if (channelStatus.value === 3) instagramConvoFilter = 'comment'
      }

      const queryParams = {
         id: selectedConversation,
         cursor: cursor || nextMessagesCursor,
         filter: instagramConvoFilter
      }

      if (nextMessagesCursor) {
         return await MessagesService
            .fetchMessages(queryParams)
            .then(({ data }) => {
               setMessages(messages => [...messages, ...data.messages])
               setNextMessagesCursor(data.next_cursor)
               return data
            })
            .catch((err) => {
               console.log(err)
            })
      }
   }

   useEffect(() => {
      if (selectedConversationRef.current !== 0) {
         if (autoReadStatus) {
            var bodyFormData = new FormData()

            bodyFormData.append('dialog_id', selectedConversationRef.current)
            bodyFormData.append('status', 3)

            axios({
               method: 'post',
               url: `/api/v1/message/status?`,
               headers: { 'Content-Type': 'multipart/form-data' },
               data: bodyFormData,
               withCredentials: true,
            })
               .then((response) => {
                  if (response.data.error === true) {
                     window.alert(response.data.data.error)
                  } else {
                     setDialogs(dialogs => {
                        return dialogs.map(dialog => {
                           if (
                              dialog.dialog_id === selectedConversationRef.current
                           ) {
                              return { ...dialog, status: 3, new_message: 0 }
                           }
                           return dialog
                        })
                     })
                     fetchMessages()
                  }
               })
               .catch((err) => {
                  console.log(err)
               })
         } else {
            fetchMessages()
         }
      }
      setInstagramConvoFilter(null)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [selectedConversation])

   const fetchDialog = async (id) => {
      try {
         const data = await DialogsService.fetchDialog(id)
         if (!data.error) {
            return getNormalizedDialog(data.data.dialogs[0])
         }
      } catch (err) {
         console.log(err)
      }
   }

   const hoistDialogOnNewMessage = async (id) => {
      const dialog = await fetchDialog(id)
      if (dialog) {
         setDialogs(dialogs => {
            return [dialog, ...dialogs.slice(0, dialogs.length - 1)]
         })
      }
   }

   const updateDialogsOnNewMessage = (newMessage) => {
      setDialogs(dialogs => {
         const newMessageDialog = dialogs.find(dialog => dialog.dialog_id === newMessage.dialog_id)

         if (newMessageDialog) {
            const updatedNewMessageDialog = {
               ...newMessageDialog,
               status: newMessage.is_incoming ? 1 : newMessageDialog.status,
               is_incoming: newMessage.is_incoming,
               last_message: newMessage.text,
               is_document: newMessage.type, 
               new_message: newMessage.is_incoming ? newMessageDialog.new_message + 1 : newMessageDialog.new_message 
            }
            const filteredDialogs = dialogs.filter(dialog => dialog.dialog_id !== newMessageDialog.dialog_id)
            return [updatedNewMessageDialog, ...filteredDialogs]
         } else {
            hoistDialogOnNewMessage(newMessage.dialog_id)
            return dialogs
         }
      })
   }

   const handleGettingNewMessage = (newMessage) => {
      !newMessage.self_send && Notifications.createNotification(newMessage)
      handleGettingNewNotification(newMessage.dialog_id)
      updateDialogsOnNewMessage(newMessage)
      if (
         selectedConversationRef.current !== 0 && 
         selectedConversationRef.current === newMessage.dialog_id
      ) {
         if (
            instagramConvoFilterRef.current &&
            instagramConvoFilterRef.current === 'comment' &&
            newMessage.comment_id === null
         ) return

         setMessages(messages => [newMessage, ...messages])
      }
   }

   const newMessageSubscriber = async (newMessage) => {
      if (!newMessage.eventType) {
         handleGettingNewMessage(newMessage)
         return
      }
      switch (newMessage.eventType) {
         case socketEventTypes.MESSAGE:
            handleGettingNewMessage(newMessage.payload)
            break
         default:
            return
      }
   }

   const startMessengerWS = () => {
      const postfix = isManager ? '-' : ''

      MessengerWS.subscribe(`PERSONAL_USER_CHANNEL_${userId}#${postfix}${currentUser.id}`, newMessageSubscriber)
   }

   const stopMessengerWS = () => {
      MessengerWS.unsubscribe()
   }

   const handleReadMessages = async (dialogId, unreadMessagesIds) => {
      const bodyFormData = new FormData()
      bodyFormData.append('dialog_id', dialogId)
      bodyFormData.append('status', 3)
      bodyFormData.append('external_id', unreadMessagesIds)
      setUnreadMessagesIds([])
      try {
         const { data } = await axios({
            method: 'post',
            url: `/api/v1/message/status?`,
            headers: {
               'Content-Type': 'multipart/form-data',
            },
            data: bodyFormData,
            withCredentials: true,
         })

         if (data.error) {
            console.log(data.data.error)
         } else {
            setDialogs(dialogs => dialogs.map(dialog => (
               dialog.dialog_id === dialogId
                  ? { 
                     ...dialog, 
                     new_message: dialog.new_message - unreadMessagesIds.length
                  }
                  : dialog
            )))

            unreadMessagesIds.forEach(id => (
               setMessages(messages => messages.map(message => (
                  message.external_id === id 
                     ? { ...message, status: 3 } 
                     : message
            )))))
         }
      } catch (err) {
         console.log(err)
      }
   }

   const handleContactCardClick = (contact) => {
      const { dialog } = contact
      const selectedDialog = getNormalizedDialog(dialog)
      setDialog(selectedDialog)
      setSelectedConversation(contact.dialog.id)
      setCurrentContact(contact)
   }

   const handleMessageStatusClick = async (messageData) => {
      if (!autoReadStatus) {
         try {
            const bodyFormData = new FormData()
            bodyFormData.append('status', messageData.status === 1 ? 3 : 1)
            bodyFormData.append('external_id', [messageData.external_id])

            const { data } = await axios({
               method: 'post',
               url: `/api/v1/message/status?`,
               headers: {
                  'Content-Type': 'multipart/form-data',
               },
               data: bodyFormData,
               withCredentials: true,
            })

            if (data.error) {
               alert('Произошла ошибка!')
            } else {
               setDialogs(dialogs => dialogs.map(dialog => {
                  return dialog.dialog_id === messageData.dialog_id
                     ? { 
                        ...dialog, 
                        new_message: messageData.status === 1 ? dialog.new_message - 1 : dialog.new_message + 1 
                     }
                     : dialog
               }))
               setMessages(messages => messages.map(message => (
                  message.id === messageData.id
                     ? { ...message, status: message.status === 1 ? 3 : 1 }
                     : message
               )))
            }
         } catch (err) {
            console.log(err)
            alert('Произошла ошибка!')
         }
      } else {
         alert('Отключите авто прочтение для использования функционала!')
      }
   }

   const handleEditManagerFormSubmit = async (id, { username, email }) => {
      try {
         const formData = new FormData()
         formData.append('username', username)
         formData.append('email', email)

         const data = await ManagersService.updateManager(id, formData)
         if (!data.error) {
            setManagerList(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     username,
                     email
                  }
                  : manager
            )))
            setManagers(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     username,
                     email
                  }
                  : manager
            )))
            setAddManagerPage(data => ({
               ...data,
               username,
               email
            }))
         } else {
            return Promise.reject(data.data.error)
         }
      } catch (err) {
         return Promise.reject('Не удалось обновить данные. Пожалуйста обновите страницу')
      }
   }

   const handleToggleManagerActivation = async (id, value) => {
      const isManagerActive = value !== managerStatuses.active
      const action = isManagerActive ? ManagersService.deactivate : ManagersService.activate
      try {
         const data = await action(id)
         if (!data.error) {
            setManagerList(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     status: isManagerActive ? managerStatuses.inactive : managerStatuses.active
                  }
                  : manager
            )))
            setManagers(managers => managers.map(manager => (
               manager.id === id
                  ? {
                     ...manager,
                     status: isManagerActive ? managerStatuses.inactive : managerStatuses.active
                  }
                  : manager
            )))
            setAddManagerPage(data => ({
               ...data,
               status: isManagerActive ? managerStatuses.inactive : managerStatuses.active
            }))
         }
      } catch (err) {
         alert('Не удалось обновить статус менеджера')
      }
   }

   const handleDeleteManager = async (id) => {
      try {
         const data = await ManagersService.delete(id)
         if (!data.error) {
            setManagerList(managers => managers.filter(manager => manager.id !== id))
            setManagers(managers => managers.filter(manager => manager.id !== id))
            setAddManagerPage(0)
         }
      } catch (err) {
         alert('Не удалось удалить менеджера')
      }
   }

   const handleCompleteOnboarding = async (eventData) => {
      try {
         const data = await AuthService.sendUserEvent(eventData)
         if (data.error) alert('Не удалось пропустить обучение')
      } catch (err) {
         alert('Не удалось пропустить обучение')
      }
   }

   const [selectedRepliedMessage, setSelectedRepliedMessage] = useState(null)
   
   const handleReplyMessageClick = async (id, nextMessagesData) => {
      const messagesList = nextMessagesData?.messages || messages
      const nextCursor = nextMessagesData?.next_cursor

      const originalMessage = messagesList.find(message => message.id === id)
      if (originalMessage) {
         setSelectedRepliedMessage(id)
      } else {
         const data = await getNextCursorDialogList(nextCursor)
         handleReplyMessageClick(id, data)
      }
   }

   useEffect(() => Notifications.startGettingNotifications(), [])

   useEffect(() => {
      if (unreadMessagesIds.length) {
         const timerId = setTimeout(() => {
            handleReadMessages(selectedConversationRef.current, unreadMessagesIds)
         }, 1000)
         return () => clearTimeout(timerId)
      }
   }, [unreadMessagesIds])

   useEffect(() => {
      setAddManagerPage(0)
      setManagerCardClick(0)
      setSelectedConversation(0)
      if (sidebarToggle === 1) {
         startMessengerWS()
      }

      return () => {
         stopMessengerWS()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [sidebarToggle])

   useEffect(() => {
      if (sidebarToggle === 1) {
         setDialogs([])
         setDialogsSearchValue('')
         setDialogsUnreadOnly(false)
         const search = mapDialogsFilterToSearch(DEFAULT_DIALOGS_FILTER)
         navigate(search)
         setShouldFetchDialogs(false)
         setShouldUpdateQuery(false)

         const query = mapDialogsSearchToQuery(location.search)
         const currentFilter = mapDialogsSearchToFilter(location.search)
         setDialogsFilter(currentFilter)
         setDialogsSearchValue(currentFilter.searchValue)
         setDialogsUnreadOnly(currentFilter.unreadOnly)
         fetchDialogsFirstPortion(query)
      }
   }, [sidebarToggle])

   useEffect(() => {
      if (sidebarToggle === 1) {
         if (shouldUpdateQuery) {
            const search = mapDialogsFilterToSearch(dialogsFilter)
            navigate(search)
            setShouldFetchDialogs(true)
         } else {
            setShouldUpdateQuery(true)
         }
      }
   }, [dialogsFilter])

   useEffect(() => {
      if (sidebarToggle === 1) {
         if (shouldFetchDialogs) {
            setDialogs([])
            const query = mapDialogsFilterToQuery(dialogsFilter)
            fetchDialogsFirstPortion(query)
         }
      }
   }, [dialogsSearchValue, dialogsUnreadOnly])

   // useEffect(() => {
   //    if(instagramConvoFilterRef.current !== 'all') {
   //       setNewMessages([])
   //       fetchDialogData()
   //    }
   //    // eslint-disable-next-line react-hooks/exhaustive-deps
   // }, [instagramConvoFilterRef.current])

   useEffect(() => {
      axios
         .get(`/api/v1/channel/get?`, { withCredentials: true })
         .then(function (response) {
            // handle success
            setChannelList(response.data.data)
         })
         .catch(function (error) {
            // handle error
            console.log(error)
         })

      let dialogFinder = new URLSearchParams(window.location.search).get(
         'dialog'
      )
      if (dialogFinder !== null) {
         try {
            let decodedData = atob(dialogFinder)
            let regex = /username=(\w+)&channel_name=(\w+)/g
            let match = regex.exec(decodedData)
            let params = {
               username: match[1],
               channel: match[2],
            }
            axios
               .get(`/api/v1/dialog/get?${decodedData}`, {
                  withCredentials: true,
               })
               .then(function (response) {
                  if (response.data.error === true) {
                     setNewContactInitiationParams(params)
                     setSidebarToggle(2)
                     setShowAddContactModal(true)
                  } else {
                     setSelectedConversation(response.data.data.id)
                  }
               })
               .catch(function (error) {
                  console.log(error)
               })
         } catch (error) {
            alert('Ссылку невозможно открыть!')
         }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      setManagerSidebarLoader(true)
      axios
         .get(`/api/v1/user/managers`, { withCredentials: true })
         .then(function (response) {
            // handle success
            setManagerList(response.data.data)
            setManagers(response.data.data)
            setManagerSidebarLoader(false)
         })
         .catch(function (error) {
            // handle error
            console.log(error)
            setManagerSidebarLoader(false)
         })
   }, [])

   useEffect(() => {
      if (location !== null && location.pathname !== null) {
         if (location.pathname === '/dialogs') {
            setSidebarToggle(1)
            setAddManagerPage(0)
         } else if (location.pathname === '/contacts') {
            setSidebarToggle(2)
            setAddManagerPage(0)
         } else if (location.pathname === '/managers' && !isManager) {
            setSidebarToggle(3)
         } else if (location.pathname === '/settings') {
            setSidebarToggle(4)
         } else if (location.pathname === '/chat-bots' && !isManager) {
            setSidebarToggle(4)
            setSettingsToggle(1)
         } else if (location.pathname === '/template') {
            setSidebarToggle(4)
            setSettingsToggle(2)
         } else if (location.pathname === '/tags-statuses' && !isManager) {
            setSidebarToggle(4)
            setSettingsToggle(3)
         } else if (location.pathname === '/dialog-settings') {
            setSidebarToggle(4)
            setSettingsToggle(4)
         } else if (location.pathname === '/mailing') {
            setSidebarToggle(5)
            setToggleSidebarView(false)
         }
      }
      if (sidebarToggle !== 1) {
         window.history.pushState('dialogs', 'dialogs', location.pathname);
      } else {
         window.history.pushState('dialogs', 'dialogs', '/dialogs');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [sidebarToggle])

   if (messengerLoader) {
      return (
         <div className="auth-loader">
            <div className="lds-ellipsis">
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      )
   } else {
      if (width > 900) {
         return (
            <div className="messenger">
               <Onboarding
                  isCompleted={isOnboardingCompleted}
                  onComplete={handleCompleteOnboarding}
                  setSidebarToggle={setSidebarToggle}
                  setToggleSidebarView={setToggleSidebarView}
                  handleMessageTextChange={handleMessageTextChange}
               />
               <div
                  className={
                     toggleSidebarViewRef.current
                        ? 'messenger-wrapper-active'
                        : 'messenger-wrapper-inactive'
                  }
               >
                  <Navbar
                     setToggleSidebarView={setToggleSidebarView}
                     sidebarToggle={sidebarToggle}
                     setSidebarToggle={setSidebarToggle}
                     setAddManagerPage={setAddManagerPage}
                     isManager={isManager}
                     disableExit={disableExit}
                     setSelectedConversation={setSelectedConversation}
                     onLogout={handleLogout}
                  />
                  <Sidebar
                     handleDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
                     dialogsLoading={dialogsLoading}
                     searchValue={dialogsSearchValue}
                     isUnreadOnlyChecked={dialogsUnreadOnly}
                     handleSearchChange={handleSearchChange}
                     handleUnreadOnlyChange={handleUnreadOnlyChange}
                     setCurrentContact={setCurrentContact}
                     currentUser={currentUser}
                     saveDialog={saveDialog}
                     setDialog={setDialog}
                     isManager={isManager}
                     allowedStatus={allowedStatus}
                     allowedTag={allowedTag}
                     allowedChannel={allowedChannel}
                     handleConvoClick={setSelectedConversation}
                     dialogs={dialogs}
                     setDialogs={setDialogs}
                     userName={userName}
                     sidebarToggle={sidebarToggle}
                     setAddManagerPage={setAddManagerPage}
                     setManagerCardClick={setManagerCardClick}
                     handleScroll={handleDialogScroll}
                     newContactInitiationParams={newContactInitiationParams}
                     showAddContactModal={showAddContactModal}
                     setShowAddContactModal={setShowAddContactModal}
                     autoReadStatus={autoReadStatus}
                     setAutoReadStatus={setAutoReadStatus}
                     setSettingsToggle={setSettingsToggle}
                     channelList={channelList}
                     toggleSidebarView={toggleSidebarView}
                     setToggleSidebarView={setToggleSidebarView}
                     width={width}
                     selectedConversation={selectedConversation}
                     setSelectedConversation={setSelectedConversation}
                     handleContactCardClick={handleContactCardClick}
                     managerSidebarLoader={managerSidebarLoader}
                     managers={managers}
                     managerList={managerList}
                     setManagerList={setManagerList}
                  />
                  {selectedConversation ||
                  sidebarToggle === 5 ||
                  addManagerPage ||
                  managerCardClick ||
                  settingsToggle !== 0 ? (
                     <Mainbar
                        setDialog={setDialog}
                        currentContact={currentContact}
                        setCurrentContact={setCurrentContact}
                        dialog={dialog}
                        handleDialogTagSelect={handleDialogTagSelect}
                        handleDialogStatusSelect={handleDialogStatusSelect}
                        currentUser={currentUser}
                        allowedStatus={allowedStatus}
                        allowedTag={allowedTag}
                        allowedChannel={allowedChannel}
                        isManager={isManager}
                        templates={templates}
                        setTemplates={setTemplates}
                        tag={tag}
                        setTag={setTag}
                        addTag={addTag}
                        deleteTag={deleteTag}
                        editTag={editTag}
                        status={status}
                        setStatus={setStatus}
                        addStatus={addStatus}
                        deleteStatus={deleteStatus}
                        editStatus={editStatus}
                        isMainbarTablet={false}
                        setSelectedConversation={setSelectedConversation}
                        selectedConversation={selectedConversation}
                        setDialogs={setDialogs}
                        dialogs={dialogs}
                        messages={messages}
                        setMessages={setMessages}
                        nextMessagesCursor={nextMessagesCursor}
                        setNextMessagesCursor={setNextMessagesCursor}
                        messagesLoader={messagesLoader}
                        setMessagesLoader={setMessagesLoader}
                        channelStatus={channelStatus}
                        setChannelStatus={setChannelStatus}
                        sidebarToggle={sidebarToggle}
                        managerCardClick={managerCardClick}
                        addManagerPage={addManagerPage}
                        messagesRef={messagesRef}
                        autoReadStatus={autoReadStatus}
                        settingsToggle={settingsToggle}
                        setAutoReadStatus={setAutoReadStatus}
                        channelList={channelList}
                        userId={userId}
                        fetchDialogData={fetchDialogData}
                        setInstagramConvoFilter={setInstagramConvoFilter}
                        toggleSidebarView={toggleSidebarView}
                        messageText={messageText}
                        onMessageTextChange={handleMessageTextChange}
                        selectTemplate={selectTemplate}
                        setSelectTemplate={setSelectTemplate}
                        setSidebarToggle={setSidebarToggle}
                        setUnreadMessagesIds={setUnreadMessagesIds}
                        handleMessageStatusClick={handleMessageStatusClick}
                        handleEditManagerFormSubmit={handleEditManagerFormSubmit}
                        handleToggleManagerActivation={handleToggleManagerActivation}
                        handleDeleteManager={handleDeleteManager}
                        handleReplyMessageClick={handleReplyMessageClick}
                        selectedRepliedMessage={selectedRepliedMessage}
                        setSelectedRepliedMessage={setSelectedRepliedMessage}
                        getNextCursorDialogList={getNextCursorDialogList}
                     />
                  ) : (
                     <div className="messenger-welcome">
                        <div className="messenger-welcome-wrapper">
                           <p className="messenger-welcome-text">
                              Здравствуйте, {userName}!
                           </p>
                           <p className="messenger-welcome-instructrions-text">
                              Выберите диалог, чтобы начать общение.
                           </p>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         )
      } else {
         return (
            <div className="messenger">
               <Onboarding
                  isCompleted={isOnboardingCompleted}
                  onComplete={handleCompleteOnboarding}
                  setSidebarToggle={setSidebarToggle}
                  setToggleSidebarView={setToggleSidebarView}
                  handleMessageTextChange={handleMessageTextChange}
               />
               <div className="messenger-wrapper-tablet">
                  {selectedConversation ||
                  addManagerPage === true ||
                  settingsToggle !== 0 ||
                  managerCardClick ? (
                     // selectedConversation === 0
                     <>
                        <Mainbar
                           setDialog={setDialog}
                           currentContact={currentContact}
                           setCurrentContact={setCurrentContact}
                           dialog={dialog}
                           handleDialogTagSelect={handleDialogTagSelect}
                           handleDialogStatusSelect={handleDialogStatusSelect}
                           currentUser={currentUser}
                           allowedStatus={allowedStatus}
                           allowedTag={allowedTag}
                           allowedChannel={allowedChannel}
                           isManager={isManager}
                           templates={templates}
                           setTemplates={setTemplates}
                           tag={tag}
                           setTag={setTag}
                           addTag={addTag}
                           deleteTag={deleteTag}
                           editTag={editTag}
                           status={status}
                           setStatus={setStatus}
                           addStatus={addStatus}
                           deleteStatus={deleteStatus}
                           editStatus={editStatus}
                           isMainbarTablet={true}
                           setSelectedConversation={setSelectedConversation}
                           selectedConversation={selectedConversation}
                           setDialogs={setDialogs}
                           dialogs={dialogs}
                           messages={messages}
                           setMessages={setMessages}
                           nextMessagesCursor={nextMessagesCursor}
                           setNextMessagesCursor={setNextMessagesCursor}
                           messagesLoader={messagesLoader}
                           setMessagesLoader={setMessagesLoader}
                           channelStatus={channelStatus}
                           sidebarToggle={sidebarToggle}
                           managerCardClick={managerCardClick}
                           addManagerPage={addManagerPage}
                           messagesRef={messagesRef}
                           autoReadStatus={autoReadStatus}
                           settingsToggle={settingsToggle}
                           setAutoReadStatus={setAutoReadStatus}
                           channelList={channelList}
                           userId={userId}
                           fetchDialogData={fetchDialogData}
                           setInstagramConvoFilter={setInstagramConvoFilter}
                           messageText={messageText}
                           onMessageTextChange={handleMessageTextChange}
                           selectTemplate={selectTemplate}
                           setSelectTemplate={setSelectTemplate}
                           setSidebarToggle={setSidebarToggle}
                           setUnreadMessagesIds={setUnreadMessagesIds}
                           handleMessageStatusClick={handleMessageStatusClick}
                           handleEditManagerFormSubmit={handleEditManagerFormSubmit}
                           handleToggleManagerActivation={handleToggleManagerActivation}
                           handleDeleteManager={handleDeleteManager}
                           handleReplyMessageClick={handleReplyMessageClick}
                           selectedRepliedMessage={selectedRepliedMessage}
                           setSelectedRepliedMessage={setSelectedRepliedMessage}
                           getNextCursorDialogList={getNextCursorDialogList}
                        />
                        {/*<Navbar*/}
                        {/*   sidebarToggle={sidebarToggle}*/}
                        {/*   setSidebarToggle={setSidebarToggle}*/}
                        {/*   setIsUserAuthorized={setIsUserAuthorized}*/}
                        {/*   setAddManagerPage={setAddManagerPage}*/}
                        {/*   isManager={isManager}*/}
                        {/*/>*/}
                        {/*<Sidebar*/}
                        {/*   handleConvoClick={setSelectedConversation}*/}
                        {/*   dialogStatus={dialogStatus}*/}
                        {/*   userName={userName}*/}
                        {/*   sidebarToggle={sidebarToggle}*/}
                        {/*   setAddManagerPage={setAddManagerPage}*/}
                        {/*   setManagerCardClick={setManagerCardClick}*/}
                        {/*   handleScroll={handleDialogScroll}*/}
                        {/*   newContactInitiationParams={*/}
                        {/*      newContactInitiationParams*/}
                        {/*   }*/}
                        {/*   showAddContactModal={showAddContactModal}*/}
                        {/*   setShowAddContactModal={setShowAddContactModal}*/}
                        {/*   autoReadStatus={autoReadStatus}*/}
                        {/*   setAutoReadStatus={setAutoReadStatus}*/}
                        {/*   autoReadStatusRef={autoReadStatusRef}*/}
                        {/*   settingsToggle={settingsToggle}*/}
                        {/*   setSettingsToggle={setSettingsToggle}*/}
                        {/*   userNameRef={userNameRef}*/}
                        {/*   channelList={channelList}*/}
                        {/*   toggleSidebarView={toggleSidebarView}*/}
                        {/*   setToggleSidebarView={setToggleSidebarView}*/}
                        {/*   width={width}*/}
                        {/*/>*/}
                     </>
                  ) : selectedConversation === 0 &&
                     sidebarToggle !== 5 ? (
                     //  selectedConversation ||
                     // addManagerPage === true ||
                     // settingsToggle !== 0 ||
                     // managerCardClick
                     <>
                        <Navbar
                           setToggleSidebarView={setToggleSidebarView}
                           sidebarToggle={sidebarToggle}
                           setSidebarToggle={setSidebarToggle}
                           setAddManagerPage={setAddManagerPage}
                           isManager={isManager}
                           disableExit={disableExit}
                           setSelectedConversation={setSelectedConversation}
                           onLogout={handleLogout}
                        />
                        <Sidebar
                           handleDeleteAttachedTagFromDialog={handleDeleteAttachedTagFromDialog}
                           dialogsLoading={dialogsLoading}
                           searchValue={dialogsSearchValue}
                           isUnreadOnlyChecked={dialogsUnreadOnly}
                           handleSearchChange={handleSearchChange}
                           handleUnreadOnlyChange={handleUnreadOnlyChange}
                           setDialog={setDialog}
                           setCurrentContact={setCurrentContact}
                           currentUser={currentUser}
                           saveDialog={saveDialog}
                           selectedConversation={selectedConversation}
                           setSelectedConversation={setSelectedConversation}
                           isManager={isManager}
                           allowedStatus={allowedStatus}
                           allowedTag={allowedTag}
                           allowedChannel={allowedChannel}
                           handleConvoClick={setSelectedConversation}
                           dialogs={dialogs}
                           setDialogs={setDialogs}
                           userName={userName}
                           sidebarToggle={sidebarToggle}
                           setAddManagerPage={setAddManagerPage}
                           setManagerCardClick={setManagerCardClick}
                           handleScroll={handleDialogScroll}
                           newContactInitiationParams={
                              newContactInitiationParams
                           }
                           showAddContactModal={showAddContactModal}
                           setShowAddContactModal={setShowAddContactModal}
                           autoReadStatus={autoReadStatus}
                           setAutoReadStatus={setAutoReadStatus}
                           settingsToggle={settingsToggle}
                           setSettingsToggle={setSettingsToggle}
                           channelList={channelList}
                           toggleSidebarView={toggleSidebarView}
                           setToggleSidebarView={setToggleSidebarView}
                           width={width}
                           handleContactCardClick={handleContactCardClick}
                           managerSidebarLoader={managerSidebarLoader}
                           managers={managers}
                           managerList={managerList}
                           setManagerList={setManagerList}
                        />
                     </>
                  ) : sidebarToggle === 5 ? (
                     <>
                        <Navbar
                           setToggleSidebarView={setToggleSidebarView}
                           sidebarToggle={sidebarToggle}
                           setSidebarToggle={setSidebarToggle}
                           setAddManagerPage={setAddManagerPage}
                           isManager={isManager}
                           disableExit={disableExit}
                           setSelectedConversation={setSelectedConversation}
                           onLogout={handleLogout}
                        />
                        <Mainbar
                           setDialog={setDialog}
                           currentContact={currentContact}
                           setCurrentContact={setCurrentContact}
                           dialog={dialog}
                           handleDialogTagSelect={handleDialogTagSelect}
                           handleDialogStatusSelect={handleDialogStatusSelect}
                           currentUser={currentUser}
                           allowedStatus={allowedStatus}
                           allowedTag={allowedTag}
                           allowedChannel={allowedChannel}
                           isManager={isManager}
                           templates={templates}
                           setTemplates={setTemplates}
                           tag={tag}
                           setTag={setTag}
                           addTag={addTag}
                           deleteTag={deleteTag}
                           editTag={editTag}
                           status={status}
                           setStatus={setStatus}
                           addStatus={addStatus}
                           deleteStatus={deleteStatus}
                           editStatus={editStatus}
                           isMainbarTablet={true}
                           setSelectedConversation={setSelectedConversation}
                           selectedConversation={selectedConversation}
                           setDialogs={setDialogs}
                           dialogs={dialogs}
                           messages={messages}
                           setMessages={setMessages}
                           nextMessagesCursor={nextMessagesCursor}
                           setNextMessagesCursor={setNextMessagesCursor}
                           messagesLoader={messagesLoader}
                           setMessagesLoader={setMessagesLoader}
                           channelStatus={channelStatus}
                           sidebarToggle={sidebarToggle}
                           managerCardClick={managerCardClick}
                           addManagerPage={addManagerPage}
                           messagesRef={messagesRef}
                           autoReadStatus={autoReadStatus}
                           settingsToggle={settingsToggle}
                           setAutoReadStatus={setAutoReadStatus}
                           channelList={channelList}
                           userId={userId}
                           fetchDialogData={fetchDialogData}
                           setInstagramConvoFilter={setInstagramConvoFilter}
                           messageText={messageText}
                           onMessageTextChange={handleMessageTextChange}
                           selectTemplate={selectTemplate}
                           setSelectTemplate={setSelectTemplate}
                           setSidebarToggle={setSidebarToggle}
                           setUnreadMessagesIds={setUnreadMessagesIds}
                           handleMessageStatusClick={handleMessageStatusClick}
                           handleEditManagerFormSubmit={handleEditManagerFormSubmit}
                           handleToggleManagerActivation={handleToggleManagerActivation}
                           handleDeleteManager={handleDeleteManager}
                           handleReplyMessageClick={handleReplyMessageClick}
                           selectedRepliedMessage={selectedRepliedMessage}
                           setSelectedRepliedMessage={setSelectedRepliedMessage}
                           getNextCursorDialogList={getNextCursorDialogList}
                        />
                     </>
                     ) : (
                     <div
                        className="messenger-welcome"
                        style={{ width: '100%' }}
                     >
                        <div className="messenger-welcome-wrapper">
                           <p className="messenger-welcome-text">
                              Здравствуйте, {userName}!
                           </p>
                           <p className="messenger-welcome-instructrions-text">
                              Выберите диалог, чтобы начать общение.
                           </p>
                        </div>
                     </div>
                  )}
               </div>
            </div>
         )
      }
   }
}

export default Messenger
