import $axios from "../http"

class DialogsService {
   static fetchDialogs = async (query = '') => {
      const { data } = await $axios.get('dialog/get' + query)
      return data
   }

   static fetchDialog = async (id) => {
      const { data } = await $axios.get(`dialog/get?id=${id}`)
      return data
   }

   static attachTagToDialog = async (dialogId, tagId) => {
      const { data } = await $axios.post(`dialog/set-tag?dialog_id=${dialogId}&tag_id=${tagId}`)
      return data
   }

   static deleteAttachedTagFromDialog = async (dialogId, tagId) => {
      const { data } = await $axios.delete(`dialog/delete-tag?dialog_id=${dialogId}&tag_id=${tagId}`)
      return data
   }

   static setDialogStatus = async (dialogId, statusId) => {
      const { data } = await $axios.post(`dialog/set-status?dialog_id=${dialogId}&status_id=${statusId}`)
      return data
   }
}

export default DialogsService