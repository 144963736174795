import AuthService from '../../../services/authService'
import { checkAuthSuccess, checkAuthFail, logoutSuccess, managerLoginSuccess } from '.'

export const checkAuth = () => async (dispatch) => {
   try {
      const data = await AuthService.fetchUser()

      if (!data.error) {
         dispatch(checkAuthSuccess(data.data))
      } else {
         const appLink = process.env.REACT_APP_AUTH_LINK
         const clientId = process.env.REACT_APP_CLIENT_ID
         dispatch(checkAuthFail(`${appLink}?client_id=${clientId}`))
      }
   } catch (err) {
      console.log(err)
   }
}

export const managerLogin = (authData) => async (dispatch) => {
   try {
      const { data } = await AuthService.login(authData)

      if (!data.error) {
         dispatch(managerLoginSuccess())
      } else {
         alert(data.data.error)
      }
   } catch (err) {
      if (typeof err.response === 'undefined') {
         dispatch(managerLoginSuccess())
      } else {
         alert('Произошла ошибка...')
      }
   }
}

export const logout = () => async (dispatch) => {
   try {
      const { data } = await AuthService.logout()

      if (!data.error) {
         dispatch(logoutSuccess())
         window.location.reload(false)
      } else {
         alert(data.data.error)
      }
   } catch (err) {
      console.log(err)
   }
}