import React, { useEffect, useState } from 'react'
import './EditManagerForm.css'

const EditManagerFormControl = ({
   isFormInEditMode,
   initialValue,
   id = '', 
   name = '',
   type = 'text',
   value = 'value',
   label = '',
   error,
   onChange,
   onBlur,
   onToggleFieldEditMode
}) => {
   const [isEditMode, setIsEditMode] = useState(false)

   const handleToggleEditMode = () => {
      if (isEditMode) {
         onChange({
            target: {
               name,
               value: initialValue
            }
         })
      }
      setIsEditMode(isEditMode => !isEditMode)
      onToggleFieldEditMode(!isEditMode)
   }

   useEffect(() => {
      if (!isFormInEditMode) setIsEditMode(false)
   }, [isFormInEditMode])

   return (
      <div className={`edit-manager-form-control ${isEditMode ? 'edit-manager-form-control_active' : ''}`}>
         <label 
            className={`edit-manager-form-control__label ${error ? 'edit-manager-form-control__label_error' : ''}`}
            htmlFor={id}
         >
            {error || label}
         </label>
         <div className='edit-manager-form-control__body'>
            <input 
               className='edit-manager-form-control__input'
               id={id}
               name={name}
               type={type}
               value={value}
               onChange={onChange}
               onBlur={onBlur} 
               disabled={!isEditMode}
            />
            <button
               className='edit-manager-form-control__btn'
               type='button'
               onClick={handleToggleEditMode}
            >
               {isEditMode
                  ? (
                     <svg width="16" height="16" viewBox="0 0 24 24" fill="#252525" xmlns="http://www.w3.org/2000/svg">
                        <path 
                           d="M18.2997 5.70973C17.9097 5.31973 17.2797 5.31973 16.8897 5.70973L11.9997 10.5897L7.10973 5.69973C6.71973 5.30973 6.08973 5.30973 5.69973 5.69973C5.30973 6.08973 5.30973 6.71973 5.69973 7.10973L10.5897 11.9997L5.69973 16.8897C5.30973 17.2797 5.30973 17.9097 5.69973 18.2997C6.08973 18.6897 6.71973 18.6897 7.10973 18.2997L11.9997 13.4097L16.8897 18.2997C17.2797 18.6897 17.9097 18.6897 18.2997 18.2997C18.6897 17.9097 18.6897 17.2797 18.2997 16.8897L13.4097 11.9997L18.2997 7.10973C18.6797 6.72973 18.6797 6.08973 18.2997 5.70973Z" 
                           fill="#252525"
                        />
                     </svg>
                  ) : (
                     <svg
                        width="16px"
                        height="16px"
                        fill="#252525"
                        xmlns="http://www.w3.org/2000/svg"
                     >
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M1.60449 12.75C1.60449 12.5084 1.80037 12.3125 2.04199 12.3125H12.542C12.7836 12.3125 12.9795 12.5084 12.9795 12.75C12.9795 12.9916 12.7836 13.1875 12.542 13.1875H2.04199C1.80037 13.1875 1.60449 12.9916 1.60449 12.75Z"
                        />
                        <path
                           fill-rule="evenodd"
                           clip-rule="evenodd"
                           d="M9.244 1.8125C9.36009 1.81248 9.47143 1.8586 9.55351 1.94071L11.6841 4.07224C11.8549 4.24308 11.8549 4.51998 11.6841 4.69082L5.65162 10.726C5.56957 10.808 5.45826 10.8542 5.34219 10.8542H3.20801C2.96638 10.8542 2.77051 10.6583 2.77051 10.4167V8.2933C2.77051 8.17736 2.81653 8.06616 2.89846 7.98413L8.93453 1.94083C9.01658 1.85869 9.1279 1.81252 9.244 1.8125ZM9.2442 2.86897L3.64551 8.47437V9.97917H5.16092L10.7561 4.38153L9.2442 2.86897Z"
                        />
                     </svg>
                  )}
            </button>
         </div>
      </div>
   )
}

export default EditManagerFormControl