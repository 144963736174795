import React, { useState } from 'react'
import {REVOKE_STATUS} from "../utils/helper";

const ErrorDesc = ({ message, messageStatus }) => {
   const [showErrors, setShowErrors] = useState(false)

   const returnMessageStatus = (status) => {
      if (status === 0) return '/assets/chat/watch.svg'
      if (status === 1) return '/assets/chat/check.svg'
      if (status === -1) return '/assets/chat/error.svg'
      if (status === 3) return '/assets/chat/double-check.svg'
      if (status === REVOKE_STATUS) return ''
      else return
   }
   return (
      <div
         style={{ display: 'inline' }}
         onMouseEnter={() => setShowErrors(true)}
         onMouseLeave={() => setShowErrors(false)}
      >
         <img
            src={returnMessageStatus(messageStatus)}
            alt=""
            style={{ width: '10px', marginLeft: '5px' }}
         />
         {message.error_description && showErrors && (
            <div
               className="mainbar-conversation-error-desc"
               style={{
                  position: 'absolute',
                  top: '-25px',
                  right: '5px',
                  backgroundColor: '#000',
                  color: '#fff',
                  padding: '3px 5px',
                  borderRadius: '5px',
                  whiteSpace: 'nowrap',
                  width: 'auto',
                  height: 'auto',
                  cursor: 'pointer',
                  overflowWrap: 'break-word',
               }}
            >
               {message.error_description}
            </div>
         )}
      </div>
   )
}

export default ErrorDesc
