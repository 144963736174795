import $axios from '../http'

class MessagesService {
   static fetchMessages = async (params) => {
      const id = `?dialog=${params.id}`
      const cursor = params.cursor ? `&cursor=${params.cursor}` : ''
      const filter = params.filter ? `&filter=${params.filter}` : ''
      const username = params.username ? `&username=${params.username}` : ''
      const channel = params.channel ? `&channel=${params.channel}` : ''
      
      const { data } = await $axios.get(`message/get${id}${cursor}${filter}${username}${channel}`)
      return data
   }

   static sendAudio = async (body) => {
      const { data } = await $axios.post('message/audio', body, {
         headers: { 'Content-Type': 'multipart/form-data' }
      })
      return data
   }

   static outgoing = async (body) => {
      const { data } = await $axios.post('message/outgoing', body, {
         headers: { 'Content-Type': 'multipart/form-data' }
      })
      return data
   }
}

export default MessagesService