import React, { useState } from 'react'
import {REVOKE_STATUS} from "../utils/helper";

const MessageStatus = ({ message, messageStatus, onClick }) => {
   const [disableBtn, setDisableBtn] = useState(false)

   const returnMessageStatus = (status) => {
      if (status === 1) return '/assets/chat/check.svg'
      if (status === 3) return '/assets/chat/double-check.svg'
      if (status === REVOKE_STATUS) return ''
      else return
   }

   const handleClick = async () => {
      setDisableBtn(true)
      await onClick(message)
      setDisableBtn(false)
   }

   const singleCheckStyle = {
      width: '10px',
      marginLeft: '5px',
      cursor: 'pointer',
   }

   const doubleCheckStyle = {
      width: '15px',
      marginLeft: '5px',
      cursor: 'pointer',
   }

   return (
      <button 
         style={{ 
            display: 'flex',
            alignItems: 'center',
            border: 'none',
            background: 'none',
            width: '15px', 
            height: '15px'
         }}
         disabled={disableBtn}
         onClick={handleClick}
      >
         <img
            src={returnMessageStatus(messageStatus)}
            alt=""
            style={messageStatus === 1 ? singleCheckStyle : doubleCheckStyle}
         />
      </button>
   )
}

export default MessageStatus
