import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './navbar.css'
import Tooltip from '../Tooltip'
import tooltipsData from '../../utils/consts/tooltipsData'

const Navbar = ({
   setToggleSidebarView,
   sidebarToggle,
   setSidebarToggle,
   setAddManagerPage,
   isManager,
   disableExit,
   setSelectedConversation,
   onLogout
}) => {
   const [disableLogoutBtn, setDisableLogoutBtn] = useState(false)

   const iconStylesDisabled = {
      width: '28px',
      height: '28px',
   }

   const iconStylesEnabled = {
      width: '28px',
      height: '28px',
      filter:
         'invert(53%) sepia(47%) saturate(3253%) hue-rotate(162deg) brightness(97%) contrast(102%)',
   }

   const handleLogout = async () => {
      setDisableLogoutBtn(true)
      await onLogout()
      setDisableLogoutBtn(false)
   }

   return (
      <div className="navbar">
         <div className="navbar-wrapper">
            <div className="navbar-view-toggle-buttons">
               <div className="navbar-logo-block">
                  <img
                     width="54"
                     height="38"
                     src={`/assets/settings/brand.svg`}
                     alt=""
                  />
               </div>

               <Link className='navlink' to="/dialogs" id="dialogs">
                  <div
                     style={{
                        backgroundColor:
                           sidebarToggle === 1 ? '#ffffffab' : 'transparent',
                     }}
                     className="navbar-icon-section"
                     onClick={() => {
                        setSidebarToggle(1)
                        setSelectedConversation(0)
                        setAddManagerPage(false)
                        setToggleSidebarView(true)
                     }}
                  >
                     <img
                        style={
                           sidebarToggle === 1
                              ? iconStylesEnabled
                              : iconStylesDisabled
                        }
                        className="navbar-view-toggle-icon"
                        src={`/assets/settings/dialogs.svg`}
                        alt=""
                     />
                  </div>
                  <Tooltip
                     className='navlink__tooltip'
                     text={tooltipsData.navigation.dialogs}
                  />
               </Link>
               <Link className='navlink' to="/contacts" id="contacts">
                  <div
                     style={{
                        backgroundColor:
                           sidebarToggle === 2 ? '#ffffffab' : 'transparent',
                     }}
                     className="navbar-icon-section"
                     onClick={() => {
                        setSidebarToggle(2)
                        setAddManagerPage(false)
                        setToggleSidebarView(true)
                     }}
                  >
                     <img
                        style={
                           sidebarToggle === 2
                              ? iconStylesEnabled
                              : iconStylesDisabled
                        }
                        className="navbar-view-toggle-icon"
                        src={`/assets/settings/contacts.svg`}
                        alt=""
                     />
                  </div>
                  <Tooltip
                     className='navlink__tooltip'
                     text={tooltipsData.navigation.contacts}
                  />
               </Link>
               {isManager ? (
                  <></>
               ) : (
                  <Link className='navlink' to="/managers" id="managers">
                     <div
                        style={{
                           backgroundColor:
                              sidebarToggle === 3 ? '#ffffffab' : 'transparent',
                        }}
                        className="navbar-icon-section"
                        onClick={() => {
                           setSidebarToggle(3)
                           setToggleSidebarView(true)
                        }}
                     >
                        <img
                           style={
                              sidebarToggle === 3
                                 ? iconStylesEnabled
                                 : iconStylesDisabled
                           }
                           className="navbar-view-toggle-icon"
                           src={`/assets/settings/managers.svg`}
                           alt=""
                        />
                     </div>
                     <Tooltip
                        className='navlink__tooltip'
                        text={tooltipsData.navigation.managers}
                     />
                  </Link>
               )}
               <Link className='navlink' to="/mailing" id="mailing">
                  <div
                     style={{
                        backgroundColor:
                           sidebarToggle === 5 ? '#ffffffab' : 'transparent',
                     }}
                     className="navbar-icon-section"
                     onClick={() => {
                        setSidebarToggle(5)
                        setAddManagerPage(false)
                        setToggleSidebarView(false)
                     }}
                  >
                     <img
                        style={
                           sidebarToggle === 5
                              ? iconStylesEnabled
                              : iconStylesDisabled
                        }
                        className="navbar-view-toggle-icon"
                        src={`/assets/settings/mailing.svg`}
                        alt=""
                     />
                  </div>
                  <Tooltip
                     className='navlink__tooltip'
                     text={tooltipsData.navigation.mailing}
                  />
               </Link>
            </div>
            <div
               className="navbar-view-bottom-button"
               style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
               }}
            >
               <Link className='navlink' to="/settings" id="settings">
                  <div
                     style={{
                        backgroundColor:
                           sidebarToggle === 4 ? '#ffffffab' : 'transparent',
                        display: 'flex',
                        justifyContent: 'center',
                     }}
                     className="navbar-icon-section"
                     onClick={() => {
                        setSidebarToggle(4)
                        setToggleSidebarView(true)
                     }}
                  >
                     <img
                        style={
                           sidebarToggle === 4
                              ? iconStylesEnabled
                              : iconStylesDisabled
                        }
                        className="navbar-view-toggle-icon"
                        src={`/assets/settings/setting.svg`}
                        alt=""
                     />
                  </div>
                  <Tooltip
                     className='navlink__tooltip'
                     text={tooltipsData.navigation.settings}
                  />
               </Link>
               {disableExit 
                  ? '' 
                  : <div className='navlink'>
                     <button 
                        disabled={disableLogoutBtn}
                        className="navbar-icon-section" 
                        onClick={handleLogout}
                        id="logout-btn"
                     >
                        <img
                           className="navbar-view-toggle-icon"
                           src={`/assets/settings/logout.svg`}
                           alt=""
                        />
                     </button>
                     <Tooltip
                        className='navlink__tooltip'
                        text={tooltipsData.navigation.logout}
                     />
                  </div>}
            </div>
         </div>
      </div>
   )
}

export default Navbar
