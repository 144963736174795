import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import './auth.css'
import ManagerAuth from './ManagerAuth'
import { useDispatch } from 'react-redux'
import { managerLogin } from '../store/slices/auth/thunk-creators'

const pagesWithoutErrorAlert = ['/amo-login']

const Auth = ({ oAuthLink, isManager }) => {
   const search = useLocation().search
   const location = useLocation()
   const authError = new URLSearchParams(search).get('error')

   const dispatch = useDispatch()

   const isPageNeedAlertError = !!pagesWithoutErrorAlert.find((item) =>
      location.pathname.startsWith(item)
   )

   const handleManagerLogin = async (authData) => {
      await dispatch(managerLogin(authData))
   }

   useEffect(() => {
      if (authError && !isPageNeedAlertError) {
         window.alert(authError)
      }
   }, [authError])

   const [displayManagerLogin, setDisplayManagerLogin] = useState(true)
   const [isAmoLogin, setIsAmoLogin] = useState(false)

   useEffect(() => {
      if (location !== null && location.pathname !== null) {
         if (location.pathname.startsWith('/amo-login')) {
            setIsAmoLogin(true)
            setDisplayManagerLogin(false)
         }
      }
   }, [])

   const handleToggle = () => {
      setDisplayManagerLogin((prev) => !prev)
   }

   return displayManagerLogin ? (
      <div className="auth">
         <div className="login-card">
            <div className="login-card-wrapper">
               <div className="login-card-header-wrapper">
                  <h3
                     className="login-card-header-text"
                     style={{ textAlign: 'center' }}
                  >
                     Веб-Мессенджер
                  </h3>
               </div>
               <div className="login-card-body-wrapper">
                  <p
                     className="login-card-body-text"
                     style={{ textAlign: 'center' }}
                  >
                     Для авторизации/регистрации в мессенджере необходимо
                     авторизоваться через Ваш аккаунт в приложении i2crm
                  </p>
               </div>
               <div className="login-card-button-wrapper">
                  <a href={oAuthLink} style={{ textDecoration: 'none' }}>
                     <button className="login-card-button">
                        Войти через i2crm
                     </button>
                  </a>
                  <button
                     className="login-card-manager-button"
                     onClick={handleToggle}
                  >
                     Войти для менеджера
                  </button>
               </div>
            </div>
         </div>
      </div>
   ) : (
      <ManagerAuth
         handleToggle={handleToggle}
         isManager={isManager}
         isAmoLogin={isAmoLogin}
         authError={authError}
         onLogin={handleManagerLogin}
      />
   )
}

export default Auth
