import './templates.css'
import { useState } from 'react'
import PaginationItem from './PaginationItem'
import AddTemplate from '../modals/AddTemplate'
import TemplateItem from './TemplateItem'
import axios from 'axios'

const Templates = ({ templates, setTemplates }) => {
   const [isShowAddTemplateModal, setIsShowAddTemplateModal] = useState(false)
   const toggleIsShowAddTemplateModal = () => {
      setIsShowAddTemplateModal(!isShowAddTemplateModal)
   }
   const [currentTemplatesPage, setCurrentTemplatesPage] = useState(1)
   const [templatesPerPage, setTemplatesPerPage] = useState(3)
   const lastTemplateIndex = currentTemplatesPage * templatesPerPage
   const firstTemplateIndex = lastTemplateIndex - templatesPerPage
   const currentTemplates = templates.slice(
      firstTemplateIndex,
      lastTemplateIndex
   )

   const pageNumbers = []

   for (let i = 1; i <= Math.ceil(templates.length / templatesPerPage); i++) {
      pageNumbers.push(i)
   }

   const addTemplateHandler = (bodyForm) => {
      axios({
         method: 'post',
         url: `api/v1/template/add`,
         data: bodyForm,
         headers: { 'Content-Type': 'multipart/form-data' },
         withCredentials: true,
      }).then((response) => {
         if (response.data.error) {
            alert(response.data.data.error)
         } else {
            setTemplates((prevState) => {
               return [...prevState, response.data.data].sort((a, b) => {
                  if (a.name.toLowerCase() < b.name.toLowerCase()) {
                     return -1
                  }
                  if (a.name.toLowerCase() > b.name.toLowerCase()) {
                     return 1
                  }
               })
            })
         }
      })
   }

   return (
      <div className="settings-template-wrapper">
         <AddTemplate
            isVisible={isShowAddTemplateModal}
            title={'Новый шаблон'}
            text={'Создать шаблон'}
            element={''}
            accept={addTemplateHandler}
            cancel={toggleIsShowAddTemplateModal}
            setTemplates={setTemplates}
         />
         <div className="tags-status-settings_item">
            <div className="tags-status-settings_block">
               <h2 className="tags-status-settings_title">Шаблоны</h2>
               <button
                  disabled={templates.length >= 20}
                  className="tags-status-settings_button"
                  onClick={toggleIsShowAddTemplateModal}
               >
                  + Создать шаблон
               </button>
            </div>
            {currentTemplates.length === 0 && (
               <span className="tags-status-settings_subtitle">
                  У вас еще нет ни одного шаблона
               </span>
            )}
            <ul
               style={currentTemplates.length ? {} : { display: 'none' }}
               className="template-settings_list"
            >
               <li className="template-header">
                  <div className="template-header_block">
                     <div>
                        <span>Название</span>
                     </div>
                     <div>
                        <span>Текст шаблона</span>
                     </div>
                     <div>
                        <span>Вложение</span>
                     </div>
                  </div>
               </li>
               {currentTemplates.map((template) => {
                  return (
                     <TemplateItem
                        currentTemplates={currentTemplates}
                        templatesPerPage={templatesPerPage}
                        currentTemplatesPage={currentTemplatesPage}
                        setCurrentTemplatesPage={setCurrentTemplatesPage}
                        key={template.id}
                        template={template}
                        setTemplates={setTemplates}
                     />
                  )
               })}
            </ul>
            {templates.length > 3 && (
               <>
                  <PaginationItem
                     setCurrentPage={setCurrentTemplatesPage}
                     pageNumbers={pageNumbers}
                     currentPage={currentTemplatesPage}
                  />
               </>
            )}
         </div>
      </div>
   )
}

export default Templates
