import React from 'react'
import './EditManagerForm.css'

const EditManagerFormRadioBtn = ({ name, value, currentValue, text, onChange, disabled }) => {
   const isActive = value === currentValue

   return (
      <div className={`edit-manager-form-radio-btn ${isActive ? 'edit-manager-form-radio-btn_active' : ''}`}>
         <label className='edit-manager-form-radio-btn__body'>
            <input
               className='edit-manager-form-radio-btn__input'
               type='radio'
               name={name}
               value={value}
               onChange={onChange}
               disabled={disabled}
            />
            <div className='edit-manager-form-radio-btn__radio'></div>
            <p className='edit-manager-form-radio-btn__text'>{text}</p>
         </label>
      </div>
   )
}

export default EditManagerFormRadioBtn