import React from 'react'
import './mainbar.css'
import Message from "./Message";
import { getIsMessageOfPrevMailer } from '../../utils';

const Dialogs = ({
   messages,
   setImagePreviewLink,
   setImagePreviewModal,
   setImagePreviewType,
   setCommentReplySection,
   autoReadStatus,
   setImagePreviewId,
   setImagePreviewThumb,
   fetchDialogData,
   setUnreadMessagesIds,
   handleMessageStatusClick,
   handleReplyMessageClick,
   selectedRepliedMessage,
   setSelectedRepliedMessage
}) => {

   return (
      <ul className='messages-list'>
         {messages?.length ? (
            messages.map((message, index) => {
               const isMessageOfPrevMailer = getIsMessageOfPrevMailer(messages, message, index)

               return <Message
                   key={message.id}
                   message={message}
                   index={index}
                   isMessageOfPrevMailer={isMessageOfPrevMailer}
                   setImagePreviewLink={setImagePreviewLink}
                   setImagePreviewModal={setImagePreviewModal}
                   setImagePreviewType={setImagePreviewType}
                   setImagePreviewId={setImagePreviewId}
                   setImagePreviewThumb={setImagePreviewThumb}
                   setCommentReplySection={setCommentReplySection}
                   autoReadStatus={autoReadStatus}
                   fetchDialogData={fetchDialogData}
                   setUnreadMessagesIds={setUnreadMessagesIds}
                   handleMessageStatusClick={handleMessageStatusClick}
                   handleReplyMessageClick={handleReplyMessageClick}
                   selectedRepliedMessage={selectedRepliedMessage}
                   setSelectedRepliedMessage={setSelectedRepliedMessage}
               />
            })
         ) : (
            <></>
         )}
      </ul>
   )
}

export default Dialogs
