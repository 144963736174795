import React, { useState, useEffect } from 'react'
import axios from 'axios'
import './addContact.css'
import WarningBlock from './WarningBlock'
import { CSSTransition } from 'react-transition-group'
import {getUsernameFieldLabel, getUsernameFieldPlaceholder} from '../../utils'
import FormTooltip from '../FormTooltip'

const AddContact = ({
   close,
   isVisible,
   channelList,
   newContactInitiationParams,
   allowedChannel,
}) => {
   const [channelsForManagers, setChannelsForManagers] = useState(null)
   const [selectedChannelType, setSelectedChannelType] = useState(null)
   const [isSelectedChannel, setIsSelectedChannel] = useState(false)

   const [contactName, setContactName] = useState('')
   const [isContactNameFilled, setIsContactNameFilled] = useState(false)

   const [contactTelephone, setContactTelephone] = useState('')
   const [isContactTelephoneFilled, setIsContactTelephoneFilled] =
      useState(false)

   const [defaultUsername, setDefaultUsername] = useState('')
   const [isDefaultUsernameFilled, setIsDefaultUsernameFilled] = useState(false)

   const [contactMessage, setContactMessage] = useState('')
   const [isContactMessageIsFilled, setIsContactMessageIsFilled] =
      useState(false)

   const [addContactLoader, setAddContactLoader] = useState(false)

   const [isShowDropdown, setIsShowDropdown] = useState(false)

   const usernameFieldLabel = getUsernameFieldLabel(selectedChannelType?.type)
   const usernameFieldPlaceholder = getUsernameFieldPlaceholder(selectedChannelType?.type)

   const toggleIsShowDropdown = () => {
      setIsShowDropdown(!isShowDropdown)
   }

   useEffect(() => {
      if (allowedChannel !== null) {
         const channels = allowedChannel.map((channelId) => {
            return channelList.find((channel) => channelId === channel.id)
         }).filter((item) => item)
         setChannelsForManagers(channels)
      }
   }, [channelList])

   useEffect(() => {
      if (newContactInitiationParams) {
         setDefaultUsername(newContactInitiationParams.username)
         let parsedChannel =
             channelList.filter(
                 (channel) =>
                     channel.identity === parseInt(newContactInitiationParams.channel)
             )[0]
         setSelectedChannelType(parsedChannel ? parsedChannel : null)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [newContactInitiationParams, channelList])

   useEffect(() => {
      setIsSelectedChannel(false)
      setIsContactNameFilled(false)
      setIsContactTelephoneFilled(false)
      setIsDefaultUsernameFilled(false)
      // setIsContactMessageIsFilled(false)
   }, [selectedChannelType])

   const handleAddContactButton = () => {
      selectedChannelType === null
         ? setIsSelectedChannel(true)
         : setIsSelectedChannel(false)

      !contactName.length
         ? setIsContactNameFilled(true)
         : setIsContactNameFilled(false)

      !defaultUsername.length
         ? setIsDefaultUsernameFilled(true)
         : setIsDefaultUsernameFilled(false)

      // !contactMessage.length
      //    ? setIsContactMessageIsFilled(true)
      //    : setIsContactMessageIsFilled(false)

      if (
         selectedChannelType !== null &&
         !isContactNameFilled &&
         !isDefaultUsernameFilled &&
         !isContactMessageIsFilled
      ) {
         var bodyFormData = new FormData()
         bodyFormData.append('channel', selectedChannelType.id)
         bodyFormData.append('name', contactName)
         bodyFormData.append('username', defaultUsername)
         if (contactTelephone.length !== 0) {
            bodyFormData.append('phone', contactTelephone)
         }
         bodyFormData.append('text', contactMessage)

         setAddContactLoader(true)
         axios({
            method: 'post',
            url: `/api/v1/contact/add?`,
            data: bodyFormData,
            headers: { 'Content-Type': 'multipart/form-data' },
            withCredentials: true,
         })
            .then((response) => {
               if (response.data.error === false) {
                  close()
                  setDefaultUsername('')
                  setContactName('')
                  window.location.reload(false)
               } else {
                  window.alert(response.data.data.error)
               }
            })
            .catch((err) => {
               window.alert(err)
               window.location.reload(false)
            })
         setAddContactLoader(false)
      }
   }

   return (
      <CSSTransition
         in={isVisible}
         classNames="add-contact"
         timeout={500}
         mountOnEnter
         unmountOnExit
      >
         <div className="add-contact">
            {addContactLoader ? (
               <div className="mass-send-loader">
                  <div className="lds-ring">
                     <div></div>
                     <div></div>
                     <div></div>
                     <div></div>
                  </div>
               </div>
            ) : (
               <div
                  className="add-contact-wrapper"
                  onClick={(e) => e.stopPropagation()}
               >
                  <div className="add-contact-header">
                     <div className="add-contact-header-text">
                        Добавление контакта
                     </div>
                     <div className="add-contact-close">
                        <img
                           src="/assets/controls/close.svg"
                           alt=""
                           className="add-contact-close-button"
                           onClick={() => close()}
                        ></img>
                     </div>
                  </div>
                  <div className="add-contact-form-control">
                     <label
                        htmlFor=""
                        className={
                           isSelectedChannel
                              ? 'add-contact-form-control-label-red'
                              : 'add-contact-form-control-label'
                        }
                     >
                        Выбрать канал*
                     </label>
                     <div className="dropdown">
                        <div className="dropdown-wrapper">
                           <div
                              className="dropdown-current-item"
                              onClick={toggleIsShowDropdown}
                           >
                              <span className="dropdown-current-item_name">
                                 {selectedChannelType === null
                                    ? 'Только выбранные'
                                    : `${selectedChannelType.type} - ${selectedChannelType.username}`}
                              </span>
                              <svg
                                 width="24"
                                 height="24"
                                 viewBox="0 0 24 24"
                                 fill="none"
                                 xmlns="http://www.w3.org/2000/svg"
                              >
                                 {isShowDropdown ? (
                                    <path
                                       fill-rule="evenodd"
                                       clip-rule="evenodd"
                                       d="M11.2197 8.21967C11.5126 7.92678 11.9874 7.92678 12.2803 8.21967L18.2803 14.2197C18.5732 14.5126 18.5732 14.9874 18.2803 15.2803C17.9874 15.5732 17.5126 15.5732 17.2197 15.2803L11.75 9.81066L6.28033 15.2803C5.98744 15.5732 5.51256 15.5732 5.21967 15.2803C4.92678 14.9874 4.92678 14.5126 5.21967 14.2197L11.2197 8.21967Z"
                                       fill="#252525"
                                    />
                                 ) : (
                                    <path
                                       fill-rule="evenodd"
                                       clip-rule="evenodd"
                                       d="M5.21967 8.21967C5.51256 7.92678 5.98744 7.92678 6.28033 8.21967L11.75 13.6893L17.2197 8.21967C17.5126 7.92678 17.9874 7.92678 18.2803 8.21967C18.5732 8.51256 18.5732 8.98744 18.2803 9.28033L12.2803 15.2803C11.9874 15.5732 11.5126 15.5732 11.2197 15.2803L5.21967 9.28033C4.92678 8.98744 4.92678 8.51256 5.21967 8.21967Z"
                                       fill="#252525"
                                    />
                                 )}
                              </svg>
                           </div>
                           {isShowDropdown && (
                              <div className="dropdown-list">
                                 <div
                                    className="dropdown-list_wrapper"
                                    style={{ padding: '20px 24px' }}
                                 >
                                    {allowedChannel === null ? (
                                       <ul className="dropdown-list_items">
                                          {channelList.map((item) => {
                                             return (
                                                <li
                                                   className="dropdown-list_item"
                                                   style={{
                                                      margin: '0',
                                                      marginBottom: '16px',
                                                      cursor: 'pointer',
                                                   }}
                                                   key={item.id}
                                                   onClick={() => {
                                                      setSelectedChannelType(
                                                         item
                                                      )
                                                      setIsShowDropdown(false)
                                                   }}
                                                >
                                                   <div className="dropdown-list_item-block">
                                                      {item.type +
                                                         ' - ' +
                                                         item.username}
                                                   </div>
                                                </li>
                                             )
                                          })}
                                       </ul>
                                    ) : (
                                       <ul className="dropdown-list_items">
                                          {channelsForManagers.map((item) => {
                                             return (
                                                <li
                                                   className="dropdown-list_item"
                                                   style={{
                                                      margin: '0',
                                                      marginBottom: '16px',
                                                      cursor: 'pointer',
                                                   }}
                                                   key={item.id}
                                                   onClick={() => {
                                                      setSelectedChannelType(
                                                         item
                                                      )
                                                      setIsShowDropdown(false)
                                                   }}
                                                >
                                                   <div className="dropdown-list_item-block">
                                                      {item.type +
                                                         ' - ' +
                                                         item.username}
                                                   </div>
                                                </li>
                                             )
                                          })}
                                       </ul>
                                    )}
                                 </div>
                              </div>
                           )}
                        </div>
                     </div>
                  </div>
                  <div className="add-contact-form-control">
                     <label
                        htmlFor=""
                        className={
                           isContactNameFilled
                              ? 'add-contact-form-control-label-red'
                              : 'add-contact-form-control-label'
                        }
                     >
                        Имя
                     </label>
                     <input
                        type="text"
                        className={
                           isContactNameFilled
                              ? 'add-contact-form-control-input-red'
                              : 'add-contact-form-control-input'
                        }
                        placeholder="Введите имя"
                        maxLength="32"
                        onChange={(e) => setContactName(e.target.value)}
                        value={contactName}
                     />
                  </div>
                  <div className="add-contact-form-control">
                     <label
                        htmlFor=""
                        className={
                           isDefaultUsernameFilled
                              ? 'add-contact-form-control-label-red'
                              : 'add-contact-form-control-label'
                        }
                     >
                        {usernameFieldLabel}
                     </label>
                     <input
                        type="text"
                        className={
                           isDefaultUsernameFilled
                              ? 'add-contact-form-control-input-red'
                              : 'add-contact-form-control-input'
                        }
                        placeholder={usernameFieldPlaceholder}
                        onChange={(e) =>
                           setDefaultUsername(e.target.value)
                        }
                        value={defaultUsername}
                     />
                  </div>
                  <div className="add-contact-form-control">
                     <label
                        htmlFor=""
                        className={
                           isContactTelephoneFilled
                              ? 'add-contact-form-control-label-red'
                              : 'add-contact-form-control-label'
                        }
                     >
                        Контактный телефон
                     </label>
                     <input
                        type="tel"
                        className={
                           isContactTelephoneFilled
                              ? 'add-contact-form-control-input-red'
                              : 'add-contact-form-control-input'
                        }
                        placeholder="+7 (900) 000-00-00"
                        onChange={(e) => setContactTelephone(e.target.value)}
                        value={contactTelephone}
                     />
                  </div>
                  <div className="add-contact-form-control">
                     <label
                        htmlFor=""
                        className={
                           isContactMessageIsFilled
                              ? 'add-contact-form-control-label-red'
                              : 'add-contact-form-control-label'
                        }
                     >
                        Сообщение
                        <FormTooltip
                           error={isContactMessageIsFilled}
                           text={'Сообщение необходимо, чтобы создать диалог'}
                        />
                     </label>
                     <textarea
                        className={
                           isContactMessageIsFilled
                              ? 'add-contact-textarea-red'
                              : 'add-contact-textarea'
                        }
                        style={{ height: '80px', resize: 'none' }}
                        placeholder="Текст"
                        onChange={(e) => setContactMessage(e.target.value)}
                        value={contactMessage}
                     />
                  </div>
                  {(isSelectedChannel ||
                     isContactNameFilled ||
                     isDefaultUsernameFilled ||
                     isContactMessageIsFilled) && (
                     <WarningBlock
                        title={'Все поля обязательны для заполнения.'}
                     />
                  )}
                  <div className="add-contact-form-submit">
                     <button
                        className="add-contact-form-submit-button"
                        onClick={handleAddContactButton}
                     >
                        Добавить
                     </button>
                  </div>
               </div>
            )}
         </div>
      </CSSTransition>
   )
}

export default AddContact
